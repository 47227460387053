import { KeyboardEventHandler, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Divider, Grid, Stack } from '@mui/material';

import { MasterNameApi } from '@/api';
import { AppModal, FormRoot, RenderFormField } from '@/components';
import { IMasterNamePerson } from '@/models';
import { useToastStore } from '@/store';
import { parsePhoneNumber } from '@/utils';

import { MasterNameDefaultValues, MasterNameFormFields } from './data';

interface NewNameModalProps {
  onSubmitMasterName: (v: IMasterNamePerson) => void;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  selectedMasterName?: IMasterNamePerson;
}

export const NewNameModal = ({
  onSubmitMasterName,
  setOpen,
  isOpen = false,
  selectedMasterName,
}: NewNameModalProps) => {
  const { updateToast } = useToastStore();
  const methods = useForm<IMasterNamePerson>({
    mode: 'all',
    defaultValues: MasterNameDefaultValues,
  });
  const { handleSubmit, reset, getValues } = methods;

  useEffect(() => {
    if (isOpen) {
      reset(selectedMasterName || {});
    }
  }, [isOpen]);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const values = getValues();
    try {
      const payload = {
        ...values,
        address: values.address?._id,
        employerInfo: {
          ...values.employerInfo,
          address: values.employerInfo?.address?._id,
          phoneNumber: parsePhoneNumber(values.employerInfo?.phoneNumber, '+1'),
        },
        driverLicense: values.driverLicense?._id,
      };
      if (selectedMasterName) {
        const res = await MasterNameApi.updateMasterName(payload);
        onSubmitMasterName({ ...res.data, ...values });
      } else {
        const res = await MasterNameApi.createMasterNamePerson(payload);
        onSubmitMasterName(res.data);
      }
      setOpen(false);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const onKeyDownHandler: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) onSubmit();
  };

  return (
    <>
      <AppModal
        open={isOpen}
        title={`${selectedMasterName ? 'Update' : 'Add'} name details`}
        onClose={onClose}
        sx={{
          zIndex: 10000,
          '.MuiPaper-root': {
            overflow: 'visible',
          },
        }}
        onKeyDown={onKeyDownHandler}
      >
        <FormProvider {...methods}>
          <FormRoot>
            <Grid columnSpacing={2} flex={1} container>
              {MasterNameFormFields.map(({ grid, ...rest }) => {
                return (
                  <Grid key={rest.name} {...grid} item>
                    <RenderFormField {...rest} />
                  </Grid>
                );
              })}
            </Grid>
          </FormRoot>
          <Divider />
          <Stack flexDirection="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              sx={{ mt: 2.5, ml: 2, flex: 0 }}
              onClick={onClose}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="error"
              size="large"
              sx={{ mt: 2.5, ml: 2, flex: 0 }}
              onClick={handleSubmit(onSubmit)}
              fullWidth
            >
              {selectedMasterName ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </FormProvider>
      </AppModal>
    </>
  );
};
