import {
  IMasterName,
  IMasterNameOrganization,
  IMasterNamePerson,
  MasterNameType,
} from '@/models/master.names';

export const getMasterName = (masterName: IMasterName): string => {
  if (!masterName) return '';
  let optionLabel = '';
  if (masterName.type === MasterNameType.ORGANIZATION) {
    optionLabel = (masterName as IMasterNameOrganization)?.name || '';
  } else {
    const currentOption = masterName as IMasterNamePerson;
    optionLabel = `${currentOption?.firstName || ''} ${
      currentOption?.lastName || ''
    }`;
  }
  return optionLabel;
};
