import { SidebarItems1 } from '@/layouts/data';
import { AgencyType, IAgency, IUser, Role } from '@/models';

import {
  isTargetRole,
  hasOtherRoleExcept,
  isAgencyTypeRole,
} from './service.role';

interface IPermissionRoute {
  path: string;
  isAllowed: (user?: IUser, agencies?: IAgency[]) => boolean;
}

// Role permission required routes
const routes: IPermissionRoute[] = [
  {
    path: '/agencies',
    isAllowed: (user?: IUser) =>
      isTargetRole(user as IUser, Role.ACCOUNT_ADMIN) ||
      isTargetRole(user as IUser, Role.AGENCY_ADMIN),
  },
  {
    path: '/cfs',
    isAllowed: (user?: IUser) => isTargetRole(user as IUser, Role.DISPATCHER),
  },
  {
    path: '/cfs-active',
    isAllowed: (user?: IUser) => isTargetRole(user as IUser, Role.DISPATCHER),
  },
  {
    path: '/cases',
    isAllowed: (user?: IUser, agencies?: IAgency[]) => {
      return (
        isAgencyTypeRole(
          user as IUser,
          agencies as IAgency[],
          Role.AGENCY_ADMIN,
          AgencyType.POLICE,
        ) ||
        isTargetRole(user as IUser, Role.CASE_APPROVER) ||
        isTargetRole(user as IUser, Role.POLICE)
      );
    },
  },
  {
    path: '/master-index',
    isAllowed: (user?: IUser) =>
      hasOtherRoleExcept(user as IUser, [
        Role.POLICE,
        Role.CASE_APPROVER,
        Role.EMPLOYEE,
      ]),
  },
];

// Check if user has permission to access given route
export const isAllowedRoute = (
  path: string,
  user: IUser,
  agencies: IAgency[],
) => {
  const permissionRoute = routes.find((route) => path.startsWith(route.path));
  if (permissionRoute) {
    return permissionRoute.isAllowed(user, agencies);
  } else {
    return true;
  }
};

// Filter sidebar items that user has permission to access
export const getAllowedSidebarItems = (user: IUser, agencies: IAgency[]) => {
  return SidebarItems1.filter(({ link }) =>
    isAllowedRoute(link, user, agencies),
  );
};
