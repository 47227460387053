import { useMemo } from 'react';

import {
  ImageList,
  ImageListItem,
  ImageListProps,
  styled,
} from '@mui/material';

import defaultImage from '@/assets/images/pngs/image.png';
import { useImageStore } from '@/store';
import colors from '@/theme/variables/colors';

interface ImageGridProps extends Omit<ImageListProps, 'children'> {
  images: string[];
}

const ImageGridRoot = styled(ImageList)(() => ({
  width: '100%',

  '& .MuiImageListItem-root': {
    width: '100%',
    borderRadius: 8,

    '& img': {
      width: '100%',
      aspectRatio: 1.5,
      objectFit: 'cover',
      borderRadius: 8,
      border: `1px solid ${colors.grey['20']}`,
    },
  },
}));

export const ImageGrid = ({ images = [], ...rest }: ImageGridProps) => {
  const { sasToken, fetchImageToken, checkTokenExpiration } = useImageStore();

  const tokenImages = useMemo(() => {
    const isExpired = checkTokenExpiration();
    if (isExpired) {
      fetchImageToken();
      return [];
    } else {
      return images.map((image) => `${image}?${sasToken}`);
    }
  }, [images, sasToken]);

  return (
    <ImageGridRoot cols={4} gap={16} {...rest}>
      {tokenImages.map((image) => (
        <ImageListItem key={image}>
          <img src={image || defaultImage} alt="" loading="lazy" />
        </ImageListItem>
      ))}
    </ImageGridRoot>
  );
};
