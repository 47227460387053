import {
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Divider,
} from '@mui/material';

import { IcDeleteTrash, IcPencil, IcVisibility } from '@/assets/images';
import { TableContainer, TablePagination, TableColumn } from '@/components';
import { GenderList, RaceList } from '@/constants/form_inputs';
import { IMasterNamePerson } from '@/models/master.names';
import { formatPhoneNumber } from '@/utils/pattern';

import { masterIndexNameListTableColumns } from '../data';
import { masterIndexNameDummyTableData } from '../dummyData';

type NameListTableProps = {
  hiddenColumns: string[];
};

export const MasterIndexNameListTable = ({
  hiddenColumns,
}: NameListTableProps) => {
  const renderColumn = (
    row: IMasterNamePerson,
    field: { key: string; label?: string },
  ) => {
    const data = row[field.key as keyof typeof row];
    switch (field.key) {
      case 'fullName': {
        const { firstName, lastName } = row;
        return `${firstName} ${lastName}`;
      }
      case 'actions':
        return (
          <>
            <IconButton aria-label="visibility">
              <IcVisibility />
            </IconButton>
            <IconButton aria-label="edit">
              <IcPencil />
            </IconButton>
            <IconButton aria-label="delete">
              <IcDeleteTrash />
            </IconButton>
          </>
        );
      case 'race':
        return RaceList[data as keyof typeof RaceList];
      case 'phone':
        // TODO:: find out why is this empty?
        return formatPhoneNumber('');
      case 'gender':
        return GenderList[data as keyof typeof GenderList];
      default: {
        return data?.toString();
      }
    }
  };

  return (
    <Stack spacing={2} sx={{ flex: 1 }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {masterIndexNameListTableColumns.map((field) =>
                !hiddenColumns.includes(field.key) ? (
                  <TableCell key={field.key}>{field.label}</TableCell>
                ) : null,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {masterIndexNameDummyTableData.map((row: IMasterNamePerson) => (
              <TableRow key={row._id}>
                {masterIndexNameListTableColumns.map((field: TableColumn) => {
                  return !hiddenColumns.includes(field.key) ? (
                    <TableCell
                      key={field.key}
                      width={field.key === 'actions' ? 120 : 'auto'}
                      align={field.key === 'actions' ? 'right' : 'left'}
                    >
                      {renderColumn(row, field)}
                    </TableCell>
                  ) : null;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ mt: 2 }} />

      <TablePagination></TablePagination>
    </Stack>
  );
};
