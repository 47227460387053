import { FC } from 'react';
import { Controller, useFormContext, get, FieldError } from 'react-hook-form';

import { FormElementProps } from '@/components';

import { BaseAutocomplete, BaseAutocompleteProps } from '../Autocomplete';

export interface FormAutocompleteFieldProps
  extends Omit<BaseAutocompleteProps, 'value'>,
    FormElementProps {
  name: string;
  handleChange?: (name: string, value: string | string[]) => void;
}

export const FormAutocompleteField: FC<FormAutocompleteFieldProps> = (
  props,
) => {
  const { name, items, rules, handleChange, ...restProps } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = (get(errors, name) as FieldError)?.message;

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <BaseAutocomplete
          {...restProps}
          value={value}
          items={items}
          error={error}
          onChange={(e) => {
            onChange(e);
            if (handleChange) handleChange(name, e);
          }}
        />
      )}
      name={name}
      rules={rules}
    />
  );
};
