import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IIncidentCode, IUnitType, IVehicleMake } from '@/models';

import { http } from './http';

export const DataApi = {
  getIncidentCodes: async (): Promise<AxiosResponse<IIncidentCode[]>> => {
    return http.get(`api/${apiVersion}/incident-codes`);
  },
  getVehicleMakes: (): Promise<AxiosResponse<IVehicleMake[]>> => {
    return http.get(`api/${apiVersion}/vehicles/makes`);
  },
  getUnitTypes: (): Promise<AxiosResponse<IUnitType[]>> => {
    return http.get(`api/${apiVersion}/units/types`);
  },
};
