import { MouseEvent, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Badge, MenuItem } from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi, MasterNameApi, AddressApi, IncomingCallsApi } from '@/api';
import incomingCallAudio from '@/assets/audios/incoming-call.mp3';
import { IcPhone } from '@/assets/images';
import { FormRoot, NarrowButton } from '@/components';
import {
  CFSMasterNameInvolvement,
  ICFS,
  IMasterName,
  ICall,
  MasterNameType,
  PhoneNumberType,
  IPhoneNumber,
} from '@/models';
import { playAudio } from '@/services';
import { useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';
import {
  compositeObject,
  openNewWindow,
  formatPhoneNumber,
  parsePhoneNumber,
} from '@/utils';

import { CallCard } from './components/CallCard';
import { IncomingCallModalContent } from './components/IncomingCallModalContent';
import { IncomingCallDefaultValues } from './data';
import {
  IncomingCallRoot,
  IncomingCallModal,
  IncomingCallMenu,
} from './styles';
import { AddressOptionType } from '../../CustomForms/AddressForm/AddressSearch';

type IncomingCallFormProps = {
  address: string;
  reporter: string;
};

export const IncomingCall = () => {
  const { account } = useUserStore();
  const { updateToast } = useToastStore();
  const [modalTitle, setModalTitle] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberCounter, setPhoneNumberCounter] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [menuState, setMenuState] = useState<{ anchorEl: null | HTMLElement }>({
    anchorEl: null,
  });
  const openActionMenu = Boolean(menuState.anchorEl);
  const [incomingCalls, setIncomingCalls] = useState<ICall[]>([]);
  const [activeCfses, setActiveCfses] = useState<ICFS[]>([]);
  const [masterNames, setMasterNames] = useState<IMasterName[]>([]);
  const [addresses, setAddresses] = useState<AddressOptionType[]>([]);
  const menuElRef = useRef<HTMLButtonElement>(null);

  const methods = useForm<IncomingCallFormProps>({
    defaultValues: IncomingCallDefaultValues,
    mode: 'all',
  });

  const { channel: ablyIncomingCallChannel } = useChannel(
    `account:${account?._id}:incoming-call`,
    () => {
      playAudio(incomingCallAudio);
    },
  );

  const { handleSubmit, reset, setValue, watch } = methods;

  const reporterField: any = watch('reporter');
  const addressField: any = watch('address');

  useEffect(() => {
    getIncomingCalls();
  }, []);

  useEffect(() => {
    if (!reporterField?._id) {
      setValue('reporter', '');
    }
  }, [reporterField]);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ anchorEl: e.currentTarget });
  };

  const handleCloseMenu = () => {
    setMenuState({ anchorEl: null });
  };

  const handleCloseModal = () => {
    setOpen(false);
    resetModalValues();
  };

  const handleMenuItemClick = (item: ICall) => {
    fetchAPIs(item.phoneNumber);
    setModalTitle(item.phoneNumber);
    setPhoneNumber(item.phoneNumber);
    setOpen(true);
    setCallAsRead(item._id);
    setMenuState({ anchorEl: null });
  };

  const setFormValue = (field: any, obj: any) => {
    const stringifiedObj = JSON.stringify(obj);
    setValue(field, JSON.parse(stringifiedObj));
  };

  const updateFormValues = (itemData: any) => {
    if (itemData.fieldType === 'reporter') {
      const associatedSelectedReporter = masterNames.find(
        (reporter) => reporter._id === itemData.itemId,
      );
      setFormValue('reporter', associatedSelectedReporter || '');
    }

    if (itemData?.fieldType === 'address') {
      const associatedSelectedAddress = addresses.find(
        (address) => address._id === itemData.itemId,
      );
      setFormValue('address', associatedSelectedAddress || '');
    }
  };

  const resetField = (fieldName: any, newValue: any) => {
    setValue(fieldName, newValue);
  };

  const fetchAPIs = (pNumber: string) => {
    fetchActiveCfses();
    fetchMasterNames(pNumber);
  };

  const fetchRelatedAddresses = async (reporterNames: IMasterName[]) => {
    if (reporterNames) {
      try {
        const filter = JSON.stringify({
          _id: {
            $in: reporterNames.map((reporter) => reporter.address?._id) ?? [],
          },
        });

        const res = await AddressApi.getAddresses({ filter });
        setAddresses(res.data.results);
      } catch (err: any) {
        updateToast({ open: true, message: err.msg });
      }
    }
  };

  const fetchActiveCfses = async () => {
    try {
      const res = await CfsApi.activeList({
        sort: JSON.stringify({ createdAt: -1 }),
      });
      setActiveCfses(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const fetchMasterNames = async (pNumber: string) => {
    try {
      const filter = {
        phoneNumbers: {
          $elemMatch: {
            phoneNumber: pNumber,
          },
        },
        $or: [
          { type: MasterNameType.PERSON },
          { type: MasterNameType.ORGANIZATION },
        ], // Received data (if any) should be either Person or Organization type
      };

      const params = {
        filter: JSON.stringify(filter),
        sort: JSON.stringify({ firstName: 1, lastName: 1 }),
      };

      const { data } = await MasterNameApi.getMasterNames(params);

      const isOrganizationCall =
        data.results[0].type === MasterNameType.ORGANIZATION;

      if (isOrganizationCall) {
        const { name }: any = data.results[0];
        const updatedModalTitle = formatPhoneNumber(pNumber);

        const formattedTitle = `${updatedModalTitle}<br/>
            <span
              style="
                font-size: 17px;
                font-weight: 500;
              "
            >
              ${name}
            </span>
          `;

        setModalTitle(formattedTitle);
      }

      setMasterNames(!isOrganizationCall ? data.results : []);
      fetchRelatedAddresses(data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  if (ablyIncomingCallChannel) {
    ablyIncomingCallChannel.subscribe((message: any) => {
      const newCall = message.data;
      newCall.isNewCall = true;
      newCall._id = message.extras.headers.incomingCall;

      setIncomingCalls([newCall, ...incomingCalls]);
      if (menuElRef.current) menuElRef.current.click();
    });
  }

  const setCallAsRead = async (callId: string) => {
    try {
      resetField('reporter', {});
      resetField('address', '');

      const updateCallStatusPromise =
        await IncomingCallsApi.updateCallReadStatus(callId).then((res) => {
          setPhoneNumberCounter(res.data.count);
        });
      const fetchCallsPromise = getIncomingCalls();

      await Promise.all([updateCallStatusPromise, fetchCallsPromise]);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const createAndOpenCFS = async (reporterId?: string, addressId?: string) => {
    try {
      const response = await CfsApi.create();
      const cfsId = response.data._id;

      if (addressId) await CfsApi.updateAddressInfo(cfsId || '', addressId);

      if (reporterId) {
        await CfsApi.addCfsMasterName(String(cfsId), reporterId, {
          relationship: {
            behavior: 'N/A',
            involvement: [CFSMasterNameInvolvement.PRIMARY_REPORTER],
          },
        });

        const {
          employerInfo,
          phoneNumbers = [],
          address,
          driverLicense,
        } = reporterField;

        const phoneAlreadyExsists = phoneNumbers.some(
          (p: IPhoneNumber) => p.phoneNumber === phoneNumber,
        );

        const reporterPhoneNumbers = phoneAlreadyExsists
          ? [...phoneNumbers] // Phone numbers remain untouched
          : [...phoneNumbers, { phoneNumber, type: PhoneNumberType.CALLER }]; // Incoming call number with type Caller is added to the phone numbers

        MasterNameApi.updateMasterName({
          ...reporterField,
          address: address?._id,
          employerInfo: {
            ...employerInfo,
            address: employerInfo?.address?._id,
            phoneNumber: parsePhoneNumber(
              reporterField.employerInfo?.phoneNumber,
              '+1',
            ),
          },
          phoneNumbers: reporterPhoneNumbers,
          driverLicense: driverLicense?._id,
        }).catch((err: any) => {
          updateToast({ open: true, message: err.msg });
        });

        const data = compositeObject('reporter.callerPhoneNumber', phoneNumber);

        await CfsApi.update(cfsId || '', data).catch((err: any) => {
          updateToast({ open: true, message: err.msg });
        });

        await openNewWindow(`/cfs/${response.data._id}`, response.data._id);
      }

      const data = compositeObject('reporter.callerPhoneNumber', phoneNumber);

      await CfsApi.update(cfsId || '', data).catch((err: any) => {
        updateToast({ open: true, message: err.msg });
      });

      await openNewWindow(
        `/cfs/${response.data._id}/?checkAddress=${true}`,
        response.data._id,
      );

      resetModalValues();
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const resetFieldData = () => {
    setValue('reporter', '');
    setValue('address', '');
    setMasterNames([]);
    setAddresses([]);
  };

  const resetModalValues = () => {
    reset();
    setOpen(false);
    setModalTitle('');
    resetFieldData();
  };

  const onSubmit = () => {
    createAndOpenCFS(reporterField._id, addressField._id);
  };

  const getIncomingCalls = async () => {
    try {
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        limit: 1000,
      };
      const response = await IncomingCallsApi.getCalls(params);
      setIncomingCalls(response.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  return (
    <IncomingCallRoot>
      <NarrowButton
        style={{ backgroundColor: colors.error.main }}
        className="btn-circle"
        onClick={(e) => handleOpenMenu(e)}
        aria-controls={openActionMenu ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openActionMenu ? 'true' : undefined}
        ref={menuElRef}
      >
        <IcPhone />
      </NarrowButton>

      <IncomingCallModal
        open={open}
        title={formatPhoneNumber(modalTitle)}
        onClose={() => handleCloseModal()}
      >
        <>
          <Badge badgeContent={phoneNumberCounter} className="badge-counter" />
          <FormProvider {...methods}>
            <FormRoot onSubmit={handleSubmit(onSubmit)} sx={{ padding: '1px' }}>
              <IncomingCallModalContent
                addresses={addresses}
                masterNames={masterNames}
                activeCfses={activeCfses}
                updateFormValues={updateFormValues}
                setOpen={setOpen}
              />
            </FormRoot>
          </FormProvider>
        </>
      </IncomingCallModal>

      <IncomingCallMenu
        id="action-menu"
        anchorEl={menuState.anchorEl}
        open={openActionMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {incomingCalls.map(
          (item) =>
            !item.isRead && (
              <MenuItem
                key={item._id}
                sx={{ padding: 0, mb: 1 }}
                onClick={() => handleMenuItemClick(item)}
              >
                <CallCard {...item} />
              </MenuItem>
            ),
        )}
      </IncomingCallMenu>
    </IncomingCallRoot>
  );
};
