import { LocalStoragePrefix } from '@/constants';
import { IPenalCode } from '@/models';

interface LocalStorageGetParams {
  prefix?: LocalStoragePrefix;
  key: string;
  shouldParse?: boolean;
}

interface LocalStorageSetParams {
  prefix?: LocalStoragePrefix;
  key: string;
  value: any;
}

export const getLocalStorageKey = (
  key: string,
  prefix?: LocalStoragePrefix,
) => {
  if (!prefix) return key;
  return `${prefix}-${key}`;
};

export const setLocalStorageItem = ({
  prefix,
  key,
  value,
}: LocalStorageSetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  if (typeof value === 'object') {
    localStorage.setItem(storageKey, JSON.stringify(value));
  } else {
    localStorage.setItem(storageKey, value);
  }
};

export const getLocalStorageItem = ({
  prefix,
  key,
  shouldParse,
}: LocalStorageGetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  const item = localStorage.getItem(storageKey);
  return shouldParse && item ? JSON.parse(item || '') : item;
};

export const getPenalCodeLabel = (penalCode: IPenalCode) => {
  const firstPart = [
    penalCode.lawTitle,
    penalCode.section,
    penalCode.subsection,
    penalCode.statutoryClass,
    penalCode.category,
    penalCode.degree,
  ]
    .filter((item) => !!item)
    .join(' ');
  return [firstPart, penalCode.miniLawDescription]
    .filter((item) => !!item)
    .join(' - ');
};

export const createUrlWithParams = (
  _baseURL: string,
  params: Record<string, any>,
) => {
  const url = new URL(_baseURL);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );
  return url.toString();
};
