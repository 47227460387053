import { useState, Dispatch } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, InputLabel, Stack } from '@mui/material';

import { FormCard } from '@/components';
import { IMasterName } from '@/models';

import { AssociatedItem, SelectedItemProps } from './AssociatedItem';
import { NewNameModal } from '../../../CustomForms/ReporterForm/NewNameModal';
import { PrimaryReporterForm } from '../../../CustomForms/ReporterForm/PrimaryReporterForm';

interface AssociatedFormProps {
  title: string;
  items: any;
  masterNames: IMasterName[];
  updateFormValues: Dispatch<SelectedItemProps | null>;
}

export const AssociatedReporterForm = ({
  title,
  items,
  masterNames,
  updateFormValues,
}: AssociatedFormProps) => {
  const [openAddNameModal, setOpenAddNameModal] = useState<boolean>(false);
  const { getValues, setValue } = useFormContext();
  const masterNameReporter = getValues('reporter');

  const handleChange = async (_: string, newValue: any) => {
    setValue('reporter', newValue);
  };

  const handleModalOpen = () => {
    setOpenAddNameModal(true);
  };

  const handleRemove = () => {
    setValue('reporter', '');
  };

  const handleSetMasterNamePrimaryReporter = (data: IMasterName) => {
    setValue('reporter', data);
  };

  return (
    <FormCard key={title} title={title} style={{ marginTop: '1px' }}>
      {items.map(({ grid, ...rest }: any) => (
        <Grid key={rest.name} {...grid} sm={12} item>
          <NewNameModal
            onSubmitMasterName={handleSetMasterNamePrimaryReporter}
            isOpen={openAddNameModal}
            setOpen={setOpenAddNameModal}
          />
          <InputLabel>{rest.label}</InputLabel>
          <PrimaryReporterForm
            masterName={masterNameReporter}
            handleModalOpen={handleModalOpen}
            onChange={handleChange}
            onRemove={handleRemove}
          />

          {rest.associatedField && (
            <FormCard
              key={rest.associatedField.title}
              title={rest.associatedField.title}
              style={{ boxShadow: 'none', padding: 0 }}
            >
              <Stack className="item-wrapper">
                {masterNames.map((masterName, index) => (
                  <AssociatedItem
                    key={masterName._id}
                    itemId={masterName._id || null}
                    index={index}
                    data={masterName}
                    updateFormValues={updateFormValues}
                    type="reporter"
                  />
                ))}
              </Stack>
            </FormCard>
          )}
        </Grid>
      ))}
    </FormCard>
  );
};
