import { PropsWithChildren, ReactNode, isValidElement } from 'react';

import { Grid, GridProps, Typography, styled } from '@mui/material';

import { colors } from '@/theme/variables';

interface ViewItemProps extends PropsWithChildren, GridProps {
  label?: string;
  value?: string | number | boolean | Date | null | ReactNode;
  children?: ReactNode;
}

const Root = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 0px',

  '& .label': {
    width: '100%',
    flex: 1,
    fontSize: '12px',
    fontWeight: 400,
  },

  '& .value': {
    flex: 1,
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
    color: colors.grey[80],

    ...(theme.palette.mode !== 'light' && {
      color: colors.grey[30],
    }),
  },
  '& .value.is--email': {
    textTransform: 'none',
  },
}));

export const ViewItem = ({
  label,
  value,
  direction = 'row',
  children,
  ...rest
}: ViewItemProps) => {
  return (
    <Root xs={12} direction={direction} {...rest} container item>
      {label && <Typography className="label">{label}</Typography>}
      {value !== null && value !== undefined && (
        <Typography
          className={`value${
            label?.toLowerCase() === 'email address' ? ' is--email' : ''
          }`}
        >
          {isValidElement(value) ? value : value.toString()}
        </Typography>
      )}
      {children}
    </Root>
  );
};
