import { useState } from 'react';

import { Divider, IconButton, List, MenuItem, Stack } from '@mui/material';

import { Avatar } from '@/components';
import { useAuthContext } from '@/hooks';
import { IUser } from '@/models';
import { getAllowedSidebarItems } from '@/services';
import { useAgencyStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { SideBarItem } from './SideBarItem';
import { Drawer, Root, SideBarMenu } from './styles';
import { SidebarItems2 } from '../../../data';

export const SideBar = () => {
  const { logout } = useAuthContext();
  const { user } = useUserStore();
  const { agencies } = useAgencyStore();

  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const [submenuEl, setSubmenuEl] = useState<HTMLElement | undefined>();
  const allowedSidebarItems = getAllowedSidebarItems(user as IUser, agencies);
  const openMenu = Boolean(menuEl);

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  return (
    <Root>
      <Drawer variant="permanent">
        <Divider sx={{ mb: 3 }} />
        <List>
          {allowedSidebarItems.map(({ key, ...item }) => (
            <SideBarItem
              key={key}
              {...item}
              submenuEl={submenuEl}
              setSubmenuEl={setSubmenuEl}
            />
          ))}
        </List>
        <div style={{ flex: 1 }} />
        <List>
          {SidebarItems2.map(({ key, ...item }) => (
            <SideBarItem key={key} {...item} />
          ))}
        </List>
        <Divider
          sx={{ width: '100%', mb: 5, mt: 4, bgcolor: colors.grey[80] }}
        />
        <Stack flexDirection="row" style={{ alignSelf: 'center' }}>
          <IconButton onClick={(e) => setMenuEl(e.currentTarget)} sx={{ p: 0 }}>
            <Avatar
              name={`${user?.firstName} ${user?.lastName}`}
              sx={{ bgcolor: colors.grey[90] }}
            />
          </IconButton>
        </Stack>
      </Drawer>
      <SideBarMenu
        id="setting-menu"
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ ml: 2 }}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </SideBarMenu>
    </Root>
  );
};
