import { FC, useEffect, useState } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';

import { PatternInput } from '@/components';
import { useToastStore } from '@/store';
import { convertToAsteriskSSN } from '@/utils';

import { InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormSSNFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  getFullSSNApi?: () => Promise<AxiosResponse<{ ssn: string }>>;
  handleChange?: (name: string, value: string | number) => void;
}

export const FormSSNField: FC<FormSSNFieldProps> = (props) => {
  const { name, getFullSSNApi, defaultValue, handleChange, ...rest } = props;
  const [showFullSSN, setShowFullSSN] = useState<boolean>(false);
  const [loadingFullSSN, setLoadingFullSSN] = useState<boolean>(false);
  const [format, setFormat] = useState<string>('');
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();
  const error = get(errors, name) as FieldError;
  const { updateToast } = useToastStore();
  const { ssn: fieldValue, _id: itemId } = getValues();

  useEffect(() => {
    setFormat(showFullSSN ? '###-##-####' : convertToAsteriskSSN('####'));
    if (!showFullSSN) {
      trigger('ssn');
    }
  }, [showFullSSN]);

  useEffect(() => {
    setShowFullSSN(!(itemId && fieldValue));
  }, [itemId]);

  const handleShowFullSSN = async () => {
    if (getFullSSNApi) {
      try {
        if (fieldValue?.length === 4) {
          setLoadingFullSSN(true);
          const res = await getFullSSNApi();
          const { ssn } = res.data;
          setValue('ssn', ssn);
        }
        setShowFullSSN(true);
      } catch (err: any) {
        updateToast({ open: true, message: err.msg });
      } finally {
        setLoadingFullSSN(false);
      }
    }
  };

  const handleHideFullSSN = () => {
    if (fieldValue?.length === 9) {
      setShowFullSSN(false);
    }
  };

  return (
    <Stack flexDirection="row" gap={1}>
      <Box sx={{ flex: 1 }}>
        <Controller
          control={control}
          defaultValue={defaultValue || ''}
          name={name}
          render={({ field: { onChange, value, ...fieldRest } }: any) => (
            <PatternInput
              {...rest}
              {...fieldRest}
              type="text"
              displayType="input"
              allowEmptyFormatting
              format={format}
              error={error?.message}
              name={name}
              disabled={!showFullSSN}
              value={showFullSSN ? value : value?.slice(-4)}
              onValueChange={(v) => {
                if (showFullSSN) {
                  if (handleChange != null) handleChange(name, v.value ?? '');
                  onChange(v.value);
                }
              }}
            />
          )}
          rules={{
            validate: (v: string) => {
              if (v === '' || !showFullSSN) {
                return true;
              }

              if (v && v.length < 9) {
                return 'invalid SSN';
              }
            },
          }}
        />
      </Box>
      <Box sx={{ flex: 'none', mt: 3 }}>
        {itemId &&
          (loadingFullSSN ? (
            <CircularProgress size={15} color="error" />
          ) : showFullSSN ? (
            <IconButton onClick={handleHideFullSSN} sx={{ mr: -1 }}>
              <VisibilityOff fontSize="small" />
            </IconButton>
          ) : (
            <IconButton onClick={handleShowFullSSN} sx={{ mr: -1 }}>
              <Visibility fontSize="small" />
            </IconButton>
          ))}
      </Box>
    </Stack>
  );
};
