import { IFileItem } from '@/components/FilePicker/FileListItem';
import { EthnicOrigin, Gender, Race } from '@/constants/form_inputs';

import { IAddress } from './address';
import { IAuditModel, ObjectId } from './base';
import { IDriverLicense } from './driver.license';
import { IContact } from './user';

export const MasterNameBehaviors = {
  AGGRESSIVE: 'Aggressive',
  ABUSIVE: 'Abusive',
  CALM: 'Calm',
  ['N/A']: 'N/A',
} as const;
export type IMasterNameRelationBehavior = keyof typeof MasterNameBehaviors;

export enum PhoneNumberType {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  WORK = 'WORK',
  CALLER = 'CALLER',
  OTHER = 'OTHER',
}

export interface IPhoneNumber {
  phoneNumber: string;
  type: PhoneNumberType;
}

export enum EyeColor {
  BROWN = 'BROWN',
  BLUE = 'BLUE',
  HAZEL = 'HAZEL',
  AMBER = 'AMBER',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
}

export enum OtherPhysicalCharacteristics {
  MUSTACHE = 'MUSTACHE',
  BEARED = 'BEARED',
  BIG_EYES = 'BIG_EYES',
  SHORT_HAIR = 'SHORT_HAIR',
  LONG_HAIR = 'LONG_HAIR',
  STRAIGHT_HAIR = 'STRAIGHT_HAIR',
  CURLS = 'CURLS',
  FRECKLES = 'FRECKLES',
}

export interface IPersonalInfo {
  address?: IAddress;
}

export enum MasterNameType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export interface IPersonalInfoReq extends Omit<IPersonalInfo, 'address'> {
  address?: string;
}
export interface IPhysicalCharacteristics {
  weight?: number;
  height?: number;
  eyeColor?: EyeColor;
  hairColor?: string;
  others?: OtherPhysicalCharacteristics[];
}

export interface IEmployerInfo {
  name?: string;
  phoneNumber?: string;
  address?: IAddress;
}

export interface IEmployerInfoReq extends Omit<IEmployerInfo, 'address'> {
  address?: string;
}

export interface IMasterNameBase extends IAuditModel {
  // type: MasterNameType;
  files?: IFileItem[];
  isVerified?: boolean;
  address?: IAddress;
  phoneNumbers?: IPhoneNumber[];
}

export interface IMasterNamePerson extends IMasterNameBase, ObjectId {
  suffix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  aliasName?: string;
  sex?: Gender;
  birthday?: string;
  ssn?: string;
  driverLicense?: IDriverLicense;
  files?: IFileItem[];
  race?: Race;
  employerInfo?: IEmployerInfo;
  physicalCharacteristics?: IPhysicalCharacteristics;
  ethnicOrigin?: EthnicOrigin;
  linkedCFSs?: number;
  type: MasterNameType.PERSON;
}

export interface IMasterNameReq
  extends Omit<
    IMasterNamePerson,
    | 'employerInfo'
    | 'driverLicense'
    | 'address'
    | 'createdAt'
    | 'updatedAt'
    | 'type'
  > {
  employerInfo?: IEmployerInfoReq;
  driverLicense?: string;
  address?: string;
}

export type IMasterName = IMasterNamePerson | IMasterNameOrganization;
export const OrganizationType = {
  BUSINESS: 'Business',
  FINANCIAL_INSTITUTION: 'Financial institution',
  GOVERNMENT: 'Government',
  RELIGIOUS_ORGANIZATION: 'Religious organization',
  SOCIETY_PUBLIC: 'Society public',
  OTHER: 'Other',
};
export type IOrganizationType = keyof typeof OrganizationType;

export interface IMasterNameOrganization extends IMasterNameBase {
  name: string;
  type: MasterNameType.ORGANIZATION;
  emails?: string[];
  contacts?: IContact[];
  urls?: string[];
  notes?: string;
  socialMediaLinks?: string[];
  organizationType?: IOrganizationType;
}

export type IMasterOrganizationRequest = Omit<
  IMasterNameOrganization,
  'address'
> & {
  address?: string;
};

export type IMasterNameOrganizationForm = IMasterNameOrganization & {
  informationType: Array<Record<string, any>>;
};
