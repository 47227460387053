import { Dispatch, SetStateAction } from 'react';

import { Button, Grid, Stack } from '@mui/material';

import { ICFS, IMasterName } from '@/models';

import { AssociatedAddressForm } from './AssociatedAddressForm';
import { SelectedItemProps } from './AssociatedItem';
import { AssociatedReporterForm } from './AssociatedReporterForm';
import { RecentCFSCard } from './RecentCFSCard';
import { FormCard } from '../../../Cards/FormCard';
import { AddressOptionType } from '../../../CustomForms/AddressForm/AddressSearch';
import {
  IncomingCallAddressFormFields,
  IncomingCallReporterFormFields,
} from '../data';

interface IncomingCallModalContentProps {
  addresses: AddressOptionType[];
  masterNames: IMasterName[];
  activeCfses: ICFS[];
  updateFormValues: Dispatch<
    SetStateAction<SelectedItemProps | null | undefined>
  >;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const IncomingCallModalContent = ({
  addresses,
  masterNames,
  activeCfses,
  updateFormValues,
  setOpen,
}: IncomingCallModalContentProps) => {
  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item sm={12} xl={6}>
          <Grid item sm={12}>
            {IncomingCallAddressFormFields.map(({ title, items }) => (
              <AssociatedAddressForm
                key={title}
                title={title}
                items={items}
                addresses={addresses}
                updateFormValues={updateFormValues}
              />
            ))}
          </Grid>
          <Grid item sm={12}>
            {IncomingCallReporterFormFields.map(({ title, items }) => (
              <AssociatedReporterForm
                key={title}
                title={title}
                items={items}
                masterNames={masterNames}
                updateFormValues={updateFormValues}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item sm={12} xl={6}>
          <FormCard
            key="Recent CFSs and address"
            title="Recent CFSs and address"
          >
            <Stack className="recent-cfs">
              {activeCfses.map((item) => (
                <RecentCFSCard key={item._id} {...item} setOpen={setOpen} />
              ))}
            </Stack>
          </FormCard>
        </Grid>
      </Grid>
      <Stack className="btn-wrapper">
        <Button
          variant="contained"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button variant="contained" color="error" type="submit">
          Create CFS
        </Button>
      </Stack>
    </>
  );
};
