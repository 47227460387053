import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import { Grid, Stack, Typography, styled, Divider } from '@mui/material';

import { colors } from '@/theme/variables';

const Root = styled(Stack)(() => ({
  position: 'relative',
  width: 'calc(100% - 2px)',
  backgroundColor: 'white',
  borderRadius: 8,

  '&:first-of-type': {
    marginTop: 0,
  },

  'label span': {
    fontWeight: 600,
  },
  '.viewcard-title': {
    padding: '10px',
    width: '100%',
  },
  '&.grey-title .viewcard-title': {
    backgroundColor: colors.grey[10],
    fontWeight: 500,
  },
  '&.red-title .viewcard-title': {
    backgroundColor: colors.error.light,
  },
}));

interface ViewCardProps extends PropsWithChildren {
  title: string | ReactNode;
  style?: CSSProperties;
  showDivider?: boolean;
  className?: string;
}

export const ViewCard = ({
  title,
  style,
  showDivider,
  children,
  ...rest
}: ViewCardProps) => {
  return (
    <>
      <Root spacing={2} style={style} {...rest}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 1 }}
        >
          {typeof title === 'string' ? (
            <Typography variant="inherit" className="viewcard-title">
              {title}
            </Typography>
          ) : (
            title
          )}
        </Stack>
        <Grid columnSpacing={3} container style={{ marginLeft: '-24px' }}>
          {children}
        </Grid>
      </Root>
      {showDivider && <Divider sx={{ my: 3, width: '100%' }} />}
    </>
  );
};
