import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import {
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { FormElementProps } from '../types';

export type RadioItem = {
  label: string;
  value: string | number;
  disabled?: boolean;
};
export interface FormRadiosFieldProps
  extends Omit<RadioGroupProps, 'name' | 'onChange' | 'variant'>,
    FormElementProps {
  label?: string;
  items?: RadioItem[];
  handleChange?: (name: string, value: string | number) => void;
}

export const FormRadiosField = (props: FormRadiosFieldProps) => {
  const {
    defaultValue = '',
    handleChange,
    items = [],
    label,
    name,
    rules,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Stack>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }: any) => {
          return (
            <RadioGroup
              {...rest}
              defaultValue={defaultValue}
              onChange={(event: SelectChangeEvent) => {
                if (handleChange != null)
                  handleChange(event.target.name, event.target.value);
                field.onChange(event);
              }}
            >
              {items.map((radioItem, index) => (
                <FormControlLabel
                  control={<Radio disabled={radioItem.disabled} />}
                  key={index}
                  label={radioItem.label}
                  value={radioItem.value}
                />
              ))}
            </RadioGroup>
          );
        }}
        rules={rules}
        defaultValue={defaultValue}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </Stack>
  );
};
