import { ChangeEvent, FC, useEffect, useRef } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';
import SignaturePad from 'react-signature-pad-wrapper';

import { Button, InputLabel, Stack } from '@mui/material';

import { colors } from '@/theme/variables';

import { Input, InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormSignatureFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string | number) => void;
}

export const FormSignatureField: FC<FormSignatureFieldProps> = (props) => {
  const { name, rules, defaultValue, handleChange, label, ...rest } = props;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  const signatureRef = useRef<SignaturePad>(null);

  useEffect(() => {
    if (signatureRef && signatureRef.current) {
      (signatureRef?.current as SignaturePad).instance.addEventListener(
        'endStroke',
        endStrokeListener,
      );
      return () => {
        signatureRef.current?.instance.removeEventListener(
          'endStoke',
          endStrokeListener,
        );
      };
    }
  }, []);

  const endStrokeListener = () => {
    const signatureData = signatureRef.current?.instance.toDataURL();
    setValue(name, signatureData);
  };

  const clearSignaturePad = () => {
    signatureRef.current?.clear();
    setValue(name, '');
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Stack sx={{ border: `1px solid ${colors.grey[20]}`, mb: 1 }}>
        <SignaturePad ref={signatureRef} height={150} />
        <Controller
          control={control}
          defaultValue={defaultValue || ''}
          name={name}
          render={({ field }: any) => (
            <Input
              {...rest}
              {...field}
              error={error?.message}
              name={name}
              disabled
              type="hidden"
              sx={{ display: 'none' }}
              onChange={(
                event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
              ) => {
                if (handleChange != null)
                  handleChange(event.target.name, event.target.value);
                field.onChange(event);
              }}
            />
          )}
          rules={rules}
        />
      </Stack>
      <Button
        onClick={clearSignaturePad}
        variant="outlined"
        color="error"
        sx={{ mb: 2 }}
      >
        clear signature
      </Button>
    </>
  );
};
