import { Stack, Typography, styled } from '@mui/material';
import moment from 'moment';

import { IcIncomingCall } from '@/assets/images';
import { ICall } from '@/models';
import { colors } from '@/theme/variables';
import { formatPhoneNumber } from '@/utils';

const CallCardRoot = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: '227px', // To match design
  borderRadius: '6px',
  border: `1px solid ${colors.grey[10]}`,
  padding: '10px',

  '&.is-new-call': {
    backgroundColor: 'rgb(238, 62, 92, 0.05)',
    '& .svg-phone path': {
      stroke: colors.error.main,
    },
    '&:hover': {
      backgroundColor: 'rgb(238, 62, 92, 0.4)',
    },
  },
  '& .svg-phone': {
    marginRight: '13px',
  },
  '&:hover': {
    backgroundColor: colors.grey[10],
  },

  '& .call-number': {
    lineHeight: 'normal',
  },

  '& .call-info': {
    fontSize: '12px',
    lineHeight: 'normal',
  },
}));

interface CallCardProps extends ICall {
  isNewCall?: boolean;
}

const TIME_FORMAT = 'HH:mm';

export const CallCard = ({
  phoneNumber,
  provider,
  startedAt,
  isNewCall,
}: CallCardProps) => {
  return (
    <CallCardRoot className={isNewCall ? 'is-new-call' : ''}>
      <IcIncomingCall className="svg-phone" />
      <Stack>
        <Typography variant="subtitle2" className="call-number">
          {formatPhoneNumber(phoneNumber)}
        </Typography>
        <Stack>
          <Typography className="call-info">
            {provider} {moment(startedAt).format(TIME_FORMAT)}
          </Typography>
        </Stack>
      </Stack>
    </CallCardRoot>
  );
};
