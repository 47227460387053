import { Dispatch } from 'react';

import { Grid, Stack } from '@mui/material';

import { FormCard, RenderFormField } from '@/components';
import { IAddressForm } from '@/models';

import { AssociatedItem, SelectedItemProps } from './AssociatedItem';

interface AssociatedFormProps {
  title: string;
  items: any;
  addresses: IAddressForm[];
  updateFormValues: Dispatch<SelectedItemProps | null>;
}

export const AssociatedAddressForm = ({
  title,
  items,
  addresses,
  updateFormValues,
}: AssociatedFormProps) => {
  return (
    <FormCard
      key={title}
      title={title}
      style={{ marginBottom: '16px', marginTop: '1px' }}
    >
      {items.map(({ grid, ...rest }: any) => (
        <Grid key={rest.name} {...grid} sm={12} item>
          <RenderFormField {...rest} readOnly />
          {rest.associatedField && (
            <FormCard
              key={rest.associatedField.title}
              title={rest.associatedField.title}
              style={{ boxShadow: 'none', padding: 0 }}
            >
              <Stack className="item-wrapper">
                {addresses.map((address, index) => (
                  <AssociatedItem
                    key={address._id}
                    itemId={address._id || ''}
                    index={index}
                    data={address}
                    updateFormValues={updateFormValues}
                    type="address"
                  />
                ))}
              </Stack>
            </FormCard>
          )}
        </Grid>
      ))}
    </FormCard>
  );
};
