import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Divider, Stack } from '@mui/material';

import { CfsApi, UnitApi } from '@/api';
import { FormSelectField } from '@/components';
import { WithLoaderProps, withLoader } from '@/hocs';
import { ICFS, IUnit, IUnitShiftForm } from '@/models';
import { ConfirmTypes, useToastStore } from '@/store';

import { AppModal, BaseModalProps, UnitResourceForm } from '../..';

interface UnitShiftAssignForm extends IUnitShiftForm {
  cfsId: string;
}

export interface UnitShiftAssignModalProps
  extends Omit<BaseModalProps, 'children' | 'onClose'>,
    WithLoaderProps {
  cfsId?: string;
  unitShiftId?: string;
  unit?: IUnit;
  assignToHQ?: boolean;
  onClose: (refresh?: boolean) => void;
}

const UnitShiftAssignModal: FC<UnitShiftAssignModalProps> = ({
  cfsId,
  unitShiftId,
  unit,
  assignToHQ,
  showLoader,
  hideLoader,
  open,
  ...rest
}) => {
  const { updateToast } = useToastStore();
  const methods = useForm<UnitShiftAssignForm>();
  const { getValues, handleSubmit, reset } = methods;
  const [cfses, setCfses] = useState<ICFS[]>([]);

  const isStandBy = unit?.isStandByWhenOffDuty;
  const shouldAssignCfs = unitShiftId || isStandBy;

  const cfsSelectOptions = useMemo(() => {
    return cfses.map((cfs) => ({ label: cfs.number, value: cfs._id || '' }));
  }, [cfses]);

  useEffect(() => {
    if (shouldAssignCfs && !cfsId) {
      fetchActiveCfses();
    }
  }, [shouldAssignCfs]);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (unit) {
      reset({
        unit: unit._id,
        vehicles: unit.vehicles,
        users: unit.users,
        equipments: unit.equipments,
        polygons: unit.polygons,
        lines: unit.lines,
        points: unit.points,
      });
    }
  }, [unit]);

  const fetchActiveCfses = async () => {
    try {
      const res = await CfsApi.activeList({
        sort: JSON.stringify({ createdAt: -1 }),
      });
      setCfses(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const onSubmit = async () => {
    showLoader();
    try {
      const values = getValues();
      let _unitShiftId = unitShiftId;
      if (unit) {
        const res = await UnitApi.createUnitShift(values);
        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: 'Unit shift is now on-duty',
        });
        _unitShiftId = res.data._id;
      }
      if (shouldAssignCfs && _unitShiftId) {
        await CfsApi.assignUnitShift(
          cfsId || values.cfsId,
          _unitShiftId,
          assignToHQ,
        );
        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: 'Unit shift successfully assigned',
        });
      }
      rest.onClose(true);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    } finally {
      hideLoader();
    }
  };

  return (
    <AppModal title={unit ? unit.name : 'Select CFS'} {...rest} open={open}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {shouldAssignCfs && !cfsId && (
            <FormSelectField
              label="CFS"
              name="cfsId"
              items={cfsSelectOptions}
              rules={{ required: 'This field is required' }}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 250, width: 560 } },
              }}
            />
          )}
          {unit && (
            <UnitResourceForm
              currentUnitType={unit?.type}
              agencyId={unit?.agency}
              cfsId={cfsId}
            />
          )}

          <Divider sx={{ my: 3 }} />
          <Stack flex={1} flexDirection="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => rest.onClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="error"
              sx={{ ml: 3, px: 2 }}
            >
              {shouldAssignCfs ? 'Assign' : 'On-duty'}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </AppModal>
  );
};

export default withLoader(UnitShiftAssignModal);
