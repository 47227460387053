import { AxiosResponse } from 'axios';

import { http } from '@/api/http';
import { apiVersion } from '@/config';

export interface ImageToken {
  sasToken?: string;
  expiresOn?: string;
}

export const StorageApi = {
  getSasToken: (): Promise<AxiosResponse<AxiosResponse<ImageToken>>> => {
    return http.post(`api/${apiVersion}/storage/container-sas-token`);
  },
};
