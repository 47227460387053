import { Dispatch } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';

import { IcCircleFlowerShape } from '@/assets/images';
import {
  IAddress,
  IAddressForm,
  IMasterName,
  IMasterNamePerson,
} from '@/models';
import { getAddressLocation } from '@/services';

import { AssociatedItemForm } from '../styles';

export type SelectedItemProps = {
  fieldType: string;
  itemId: string | null;
};
interface AssociatedItemProps {
  itemId: string | null;
  index: number;
  data: IAddressForm | IMasterName;
  type: string;
  updateFormValues: Dispatch<SelectedItemProps>;
}

export const AssociatedItem = ({
  itemId,
  index,
  data,
  type,
  updateFormValues,
}: AssociatedItemProps) => {
  const { watch } = useFormContext();
  const fieldValue = watch(type);
  const isVerifiedReporter = 'isVerified' in data && data.isVerified;

  return (
    <AssociatedItemForm xs={12} key={index}>
      <Stack flex={1} flexDirection="row" alignItems="center">
        {isVerifiedReporter && (
          <Tooltip title="Verified">
            <Box sx={{ mt: 0.75, mr: 1.25 }}>
              <IcCircleFlowerShape />
            </Box>
          </Tooltip>
        )}
        <Typography className="name" variant="button">
          {'addressLabel' in data
            ? getAddressLocation(data as IAddress)
            : `${(data as IMasterNamePerson).firstName} ${
                (data as IMasterNamePerson).lastName
              }`}
        </Typography>
      </Stack>
      <Button
        color="error"
        variant="text"
        onClick={() => {
          updateFormValues({
            fieldType: type,
            itemId: fieldValue._id === data._id ? null : itemId,
          });
        }}
        sx={{ paddingRight: 0 }}
      >
        {fieldValue._id === data._id ? `Remove ${type}` : `Use ${type}`}
      </Button>
    </AssociatedItemForm>
  );
};
