import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { FormHelperText, InputBaseProps, InputLabel } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { IcArrowDown, IcClose } from '@/assets/images';

import { NoResultItem } from './NoResultItem';
import { AutoCompleteRoot, TextField } from './styles';
import { Chip } from '../../Chip';
import { BaseFieldProps } from '../types';

interface OptionType {
  inputValue?: string;
  label: string;
  value: string | number;
  subheader?: string;
}
const filter = createFilterOptions<string | OptionType>();

export interface BaseAutocompleteProps
  extends Omit<InputBaseProps, 'value' | 'error' | 'onChange'>,
    BaseFieldProps {
  value: string | string[];
  defaultValue?: OptionType;
  multiple?: boolean;
  limitTags?: number;
  items?: OptionType[];
  showPopupIcon?: true | false | 'auto';
  onChange?: (...event: any[]) => void;
  groupBy?: (option: any) => string;
}

export const BaseAutocomplete: FC<BaseAutocompleteProps> = ({
  value,
  label,
  multiple,
  limitTags,
  items = [],
  onChange,
  error,
  disabled = false,
  groupBy,
  placeholder,
  showPopupIcon,
  sx,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      newValue: string | OptionType | (string | OptionType)[] | null,
    ) => {
      if (onChange) {
        if (typeof newValue === 'string') {
          onChange(newValue);
        } else if (!Array.isArray(newValue)) {
          onChange(newValue?.value);
        } else {
          const valueArr = newValue.map((v) =>
            typeof v === 'string' ? v : v.value,
          );
          onChange(valueArr);
        }
      }
    },
    [onChange],
  );

  const getValue = useMemo(() => {
    if (typeof value === 'string') {
      return items?.find((option) => option.value === value) || '';
    }
    if (Array.isArray(value)) {
      const values: OptionType[] = [];
      value.map((item) => {
        const optionValue = items.find((option) => option.value === item);
        if (optionValue) values.push(optionValue);
      });
      return values;
    }
    return multiple ? [] : '';
  }, [value, items]);

  const getOptionLabel = useCallback((option: string | OptionType) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label;
  }, []);

  return (
    <AutoCompleteRoot sx={sx}>
      {!!label && <InputLabel>{label}</InputLabel>}
      <Autocomplete
        autoHighlight
        value={getValue}
        disableClearable={!getValue}
        disabled={disabled}
        inputValue={inputValue}
        onChange={handleChange}
        groupBy={groupBy}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={items}
        getOptionLabel={getOptionLabel}
        noOptionsText={<NoResultItem />}
        renderOption={(props, option) => (
          <li {...props}>
            {typeof option === 'string' ? option : option.label}
          </li>
        )}
        renderTags={(values: (string | OptionType)[], getTagProps) =>
          values.map((option: string | OptionType, index: number) => (
            <Chip
              variant="filled"
              color="error"
              label={typeof option === 'string' ? option : option.label}
              {...getTagProps({ index })}
              key={index}
              deleteIcon={<IcClose color="white" />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        forcePopupIcon={showPopupIcon}
        popupIcon={<IcArrowDown />}
        multiple={multiple}
        limitTags={limitTags}
        handleHomeEndKeys
        filterSelectedOptions
        selectOnFocus
        clearOnBlur
        freeSolo
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
      />
      <FormHelperText error>{error}</FormHelperText>
    </AutoCompleteRoot>
  );
};
