import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IAddress, IAddressForm } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const AddressApi = {
  getAddresses: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IAddress>>> => {
    return http.get(`api/${apiVersion}/address`, { params });
  },
  createAddress: (data: IAddressForm): Promise<AxiosResponse<IAddress>> => {
    return http.post(`api/${apiVersion}/address`, data);
  },
  updateAddress: ({
    _id,
    ...data
  }: IAddressForm): Promise<AxiosResponse<IAddress>> => {
    return http.put(`api/${apiVersion}/address/${_id}`, data);
  },
};
