import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import moment, { Moment } from 'moment';

import { DATE_FORMAT } from '@/config';

import { DateTimePicker } from '../DateTimePicker';
import { DatePickersFormFieldProps } from '../FormDateField';

export const FormDateTimeField: FC<DatePickersFormFieldProps> = (props) => {
  const {
    name,
    rules,
    defaultValue,
    format = `${DATE_FORMAT} HH:mm`,
    handleChange,
    handleAccept,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Controller
      control={control}
      defaultValue={Boolean(defaultValue) || ''}
      name={name}
      render={({ field: { onBlur, onChange, ...restField } }: any) => (
        <DateTimePicker
          {...rest}
          {...restField}
          error={error?.message}
          format={format}
          value={moment(restField.value)}
          onChange={(value: Moment) => {
            onChange(value.format(format));
            if (handleChange != null)
              handleChange(name, moment(value).format(format));
          }}
          onAccept={(value) => {
            if (handleAccept != null)
              handleAccept(name, moment(value).format(format));
          }}
        />
      )}
      rules={rules}
    />
  );
};
