import { FC, ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthContext } from '@/hooks';
import { getAllowedSidebarItems, isAllowedRoute } from '@/services';
import { useUserStore, useAgencyStore } from '@/store';

interface RouteGuardProps {
  children?: ReactElement | null;
}

export const GuestGuard: FC<RouteGuardProps> = ({ children }) => {
  const { accessToken } = useAuthContext();
  const { state } = useLocation();
  let nextPath: string =
    state?.path == null || state.path === '/auth/login' ? '/' : state.path;
  if (accessToken) {
    if (state?.search) nextPath += state.search;
    return <Navigate to={nextPath} />;
  }

  return <>{children}</>;
};

export const AuthGuard: FC<RouteGuardProps> = ({ children }) => {
  const { accessToken } = useAuthContext();
  const { account, user } = useUserStore();
  const { agencies } = useAgencyStore();
  const { pathname, search } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>();

  if (!accessToken) {
    return (
      <Navigate
        to="/auth/login"
        replace
        state={{
          path: pathname,
          search,
        }}
      />
    );
  }

  if (!account?._id || !user || !agencies.length) return null;

  if (!isAllowedRoute(pathname, user, agencies)) {
    const initialItem = getAllowedSidebarItems(user, agencies)[0];
    return <Navigate to={initialItem?.link || '/help'} />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};
