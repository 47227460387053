import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { ICFSMasterName } from '@/models';
import { INote } from '@/models/note';
import { generateFormData } from '@/utils';

import { http } from './http';
import { IGetParams, IListResponse } from './types';
import {
  IMasterName,
  IMasterNamePerson,
  IMasterNameReq,
} from '../models/master.names';

export const MasterNameApi = {
  getMasterNames: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterName>>> => {
    return http.get(`api/${apiVersion}/master-names`, { params });
  },
  deleteMasterName: (id: string): Promise<AxiosResponse<IMasterName>> => {
    return http.delete(`api/${apiVersion}/master-names/${id}`);
  },
  uploadMasterNameFiles: (files: File[]): Promise<AxiosResponse<string[]>> => {
    const formData = generateFormData({ files });
    return http.post(`api/${apiVersion}/master-names/upload`, formData);
  },
  createMasterNamePerson: (
    data: IMasterNameReq,
  ): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.post(`api/${apiVersion}/master-names/persons`, data);
  },
  getMasterName: (id: string): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.get(`api/${apiVersion}/master-names/persons/${id}`);
  },
  getNameCfsHistory: (id: string): Promise<AxiosResponse<ICFSMasterName[]>> => {
    return http.get(`api/${apiVersion}/master-names/${id}/cfs-history`);
  },
  updateMasterName: ({
    _id,
    ...data
  }: IMasterNameReq): Promise<AxiosResponse<IMasterName>> => {
    return http.put(`api/${apiVersion}/master-names/persons/${_id}`, data);
  },
  getMasterNameNote: (
    id: string,
    params: IGetParams,
  ): Promise<AxiosResponse<INote[]>> => {
    return http.get(`api/${apiVersion}/master-names/${id}/notes`, {
      params: { ...params },
    });
  },
  deleteMasterNameNote: (
    id: string,
    noteId: string,
  ): Promise<AxiosResponse<IMasterName>> => {
    return http.delete(`api/${apiVersion}/master-names/${id}/notes/${noteId}`);
  },
  updateMasterNameNote: (
    nameId: string,
    noteId: string,
    description: string,
  ): Promise<AxiosResponse<INote>> => {
    return http.put(
      `api/${apiVersion}/master-names/${nameId}/notes/${noteId}`,
      { description },
    );
  },
  getMasterNameSSN: (id: string): Promise<AxiosResponse<{ ssn: string }>> => {
    return http.get(`api/${apiVersion}/master-names/persons/${id}/ssn`);
  },
};
