import { AxiosResponse } from 'axios';

import { IFileItem } from '@/components/FilePicker/FileListItem';
import { apiVersion } from '@/config';
import {
  ICase,
  ICaseCfsLink,
  ICaseCreateRequest,
  ICaseMasterName,
  IDisposition,
  INarrative,
  INarrativeRequest,
  ICaseRejectRequest,
  ICaseMasterVehicle,
  IPriorityForm,
  IOffense,
  IOffenseRequest,
  ICaseInvolvement,
  IMasterOrganizationRequest,
  IMasterNameOrganization,
  IIncidentInfoForm,
  IMasterNamePerson,
  IOffenderForm,
  IOffenseOffender,
  IArrestee,
  ICaseCaseLink,
  ICTVCode,
  IArresteeRequest,
} from '@/models';
import { INote } from '@/models/note';
import { generateFormData } from '@/utils';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const CaseApi = {
  create: async (data: ICaseCreateRequest): Promise<AxiosResponse<ICase>> => {
    return http.post(`api/${apiVersion}/cases`, data);
  },
  createFromCsf: async (
    data: ICaseCreateRequest,
    cfsId: string,
  ): Promise<AxiosResponse<ICase>> => {
    return http.post(`api/${apiVersion}/cases/create-from-cfs/${cfsId}`, data);
  },
  update: async (
    id: string,
    data: Partial<ICaseCreateRequest>,
  ): Promise<AxiosResponse<ICase>> => {
    return http.put(`api/${apiVersion}/cases/${id}`, data);
  },
  save: async (data: ICaseCreateRequest): Promise<AxiosResponse<ICase>> => {
    return data._id ? CaseApi.update(data._id, data) : CaseApi.create(data);
  },
  getOne: async (id: string): Promise<AxiosResponse<ICase>> => {
    return http.get(`api/${apiVersion}/cases/${id}`);
  },
  list: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICase>>> => {
    return http.get(`api/${apiVersion}/cases`, { params });
  },
  listCtvCodes: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICTVCode>>> => {
    return http.get(`api/${apiVersion}/ctv-codes`, { params });
  },
  getLinkedMasterNames: async (
    caseId: string,
    params: IGetParams,
  ): Promise<AxiosResponse<ICaseMasterName[]>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/master-names`, {
      params,
    });
  },
  addDisposition: async (
    caseId: string,
    data: Omit<IDisposition, 'createdBy' | 'createdAt'>,
  ): Promise<AxiosResponse<ICase>> => {
    return http.post(`api/${apiVersion}/cases/${caseId}/dispositions`, data);
  },
  getLinkedCFS: async (
    id: string,
    params: IGetParams,
  ): Promise<AxiosResponse<ICaseCfsLink[]>> => {
    return http.get(`api/${apiVersion}/cases/${id}/cfs`, { params });
  },
  linkCFSToCase: async (
    caseId: string,
    cfsId: string,
  ): Promise<AxiosResponse<ICaseCfsLink>> => {
    return http.post(`api/${apiVersion}/cases/${caseId}/cfs/${cfsId}`);
  },
  unlinkCFSFromCase: async (
    caseId: string,
    cfsId: string,
  ): Promise<AxiosResponse<ICaseCfsLink>> => {
    return http.delete(`api/${apiVersion}/cases/${caseId}/cfs/${cfsId}`);
  },
  getLinkedCases: async (
    id: string,
    params: IGetParams,
  ): Promise<AxiosResponse<ICaseCaseLink[]>> => {
    return http.get(`api/${apiVersion}/cases/${id}/links`, { params });
  },
  linkCaseToCase: async (
    caseId: string,
    caseLinkId: string,
  ): Promise<AxiosResponse<ICaseCaseLink>> => {
    return http.post(`api/${apiVersion}/cases/${caseId}/links/${caseLinkId}`);
  },
  unlinkCaseFromCase: async (
    caseId: string,
    caseLinkId: string,
  ): Promise<AxiosResponse<ICaseCaseLink>> => {
    return http.delete(`api/${apiVersion}/cases/${caseId}/links/${caseLinkId}`);
  },
  // --------------- narratives ---------------------\\
  saveNarative: async (
    caseId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return data._id
      ? CaseApi.updateNarrative(caseId, data._id, data)
      : CaseApi.createNarrative(caseId, data);
  },
  createNarrative: async (
    caseId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return http.post(`api/${apiVersion}/cases/${caseId}/narratives`, data);
  },
  updateNarrative: async (
    caseId: string,
    narrativeId: string,
    data: INarrativeRequest,
  ): Promise<AxiosResponse<INarrative>> => {
    return http.put(
      `api/${apiVersion}/cases/${caseId}/narratives/${narrativeId}`,
      data,
    );
  },
  getLinkedNarratives: async (
    caseId: string,
    params: IGetParams = { sort: JSON.stringify({ createdAt: -1 }) },
  ): Promise<AxiosResponse<INarrative[]>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/narratives`, {
      params,
    });
  },
  deleteNarrative: async (
    caseId: string,
    narrativeId: string,
  ): Promise<AxiosResponse<ICaseCfsLink>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/narratives/${narrativeId}`,
    );
  },
  // --------------- masternames ---------------------\\
  getCaseMasterNames: async (
    caseId: string,
  ): Promise<AxiosResponse<ICaseMasterName[]>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/master-names`);
  },
  addCaseMasterName: async (
    caseId: string,
    nameId: string,
  ): Promise<AxiosResponse<ICaseMasterName>> => {
    return http.post(
      `api/${apiVersion}/cases/${caseId}/master-names/${nameId}`,
      {},
    );
  },
  updateCaseMasterName: async (
    caseId: string,
    nameId: string,
    data: { relationship: ICaseInvolvement },
  ): Promise<AxiosResponse<ICaseMasterName>> => {
    return http.patch(
      `api/${apiVersion}/cases/${caseId}/master-names/${nameId}`,
      data,
    );
  },
  deleteCaseMasterName: async (
    caseId: string,
    nameId: string,
  ): Promise<AxiosResponse<ICaseMasterName>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/master-names/${nameId}`,
    );
  },
  getCaseMasterName: async (
    caseID: string,
    nameId: string,
  ): Promise<AxiosResponse<ICaseMasterName>> => {
    return http.get(`api/${apiVersion}/cases/${caseID}/master-names/${nameId}`);
  },
  createMasterNameNote: (
    caseId: string,
    nameId: string,
    description: string,
  ): Promise<AxiosResponse<INote>> => {
    return http.post(
      `api/${apiVersion}/cases/${caseId}/master-names/${nameId}/notes`,
      {
        description,
      },
    );
  },

  // --------------- masterName cases---------------------\\
  saveMasterOrganization: async (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return data._id
      ? CaseApi.updateMasterOrganization(data._id, data)
      : CaseApi.addMasterOrganization(data);
  },
  addMasterOrganization: async (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.post(`api/${apiVersion}/master-names/organizations/`, data);
  },
  updateMasterOrganization: async (
    organizationId: string,
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.put(
      `api/${apiVersion}/master-names/organizations/${organizationId}`,
      data,
    );
  },
  deleteMasterOrganization: async (
    caseId: string,
    nameId: string,
  ): Promise<AxiosResponse<ICaseMasterName>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/master-names/${nameId}`,
    );
  },
  addUnknownOrganization: async (): Promise<
    AxiosResponse<IMasterNameOrganization>
  > => {
    return http.post(`api/${apiVersion}/master-names/organizations/unknown/`);
  },
  addUnknownPerson: async (): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.post(`api/${apiVersion}/master-names/persons/unknown/`);
  },
  //
  // --------------- update case status ---------------------\\
  submitCase: async (caseId: string): Promise<AxiosResponse<ICase>> => {
    return http.patch(`api/${apiVersion}/cases/${caseId}/submit`);
  },
  rejectCase: async (
    caseId: string,
    data: ICaseRejectRequest,
  ): Promise<AxiosResponse<ICase>> => {
    return http.patch(`api/${apiVersion}/cases/${caseId}/reject`, data);
  },
  approveCase: async (caseId: string): Promise<AxiosResponse<ICase>> => {
    return http.patch(`api/${apiVersion}/cases/${caseId}/approve`);
  },
  reopenCase: async (caseId: string): Promise<AxiosResponse<ICase>> => {
    return http.patch(`api/${apiVersion}/cases/${caseId}/reopen`);
  },
  reassignCase: async (
    caseId: string,
    supervisorId: string,
    comment: string,
  ): Promise<AxiosResponse<ICase>> => {
    return http.patch(
      `api/${apiVersion}/cases/${caseId}/assign/${supervisorId}`,
      { comment },
    );
  },
  togglePriority: async (
    caseID: string,
    data?: IPriorityForm,
  ): Promise<AxiosResponse<ICase>> => {
    return http.patch(
      `api/${apiVersion}/cases/${caseID}/toggle-priority`,
      data,
    );
  },
  // --------------- vehicles ---------------------\\
  getCaseMasterVehicles: async (
    caseId: string,
  ): Promise<AxiosResponse<ICaseMasterVehicle[]>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/master-vehicles`);
  },
  addCaseMasterVehicle: async (
    caseId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<ICaseMasterVehicle>> => {
    return http.post(
      `api/${apiVersion}/cases/${caseId}/master-vehicles/${vehicleId}`,
    );
  },
  deleteCaseMasterVehicle: async (
    caseId: string,
    vehicleId: string,
  ): Promise<AxiosResponse<ICaseMasterVehicle>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/master-vehicles/${vehicleId}`,
    );
  },
  // --------------- offenses ---------------------\\
  getOffenses: async (
    caseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IOffense>>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/offenses`, { params });
  },
  createOffense: async (
    caseId: string,
    data: IOffenseRequest,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.post(`api/${apiVersion}/cases/${caseId}/offenses`, data);
  },
  updateOffense: async (
    caseId: string,
    { _id: offenseId, ...data }: IOffenseRequest,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.put(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}`,
      data,
    );
  },
  deleteOffense: async (
    caseId: string,
    offenseId: string,
  ): Promise<AxiosResponse<IOffense>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}`,
    );
  },
  updateIncidentInfo: async (caseId: string, data: IIncidentInfoForm) => {
    return http.patch(`api/${apiVersion}/cases/${caseId}/incident-info`, data);
  },
  // --------------- offenders ---------------------\\
  getOffenseOffenders: async (
    caseId: string,
    offenseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IOffenseOffender[]>> => {
    return http.get(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}/offenders`,
      { params },
    );
  },
  createOffenseOffender: async (
    caseId: string,
    offenseId: string,
    { offenderId, ...data }: IOffenderForm,
  ): Promise<AxiosResponse<IOffenseOffender>> => {
    return http.post(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}/offenders/${offenderId}`,
      data,
    );
  },
  updateOffenseOffender: async (
    caseId: string,
    offenseId: string,
    { offenderId, ...data }: IOffenderForm,
  ): Promise<AxiosResponse<IOffenseOffender>> => {
    return http.patch(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}/offenders/${offenderId}`,
      data,
    );
  },
  deleteOffenseOffender: async (
    caseId: string,
    offenseId: string,
    offenderId: string,
  ): Promise<AxiosResponse<IOffenseOffender>> => {
    return http.delete(
      `api/${apiVersion}/cases/${caseId}/offenses/${offenseId}/offenders/${offenderId}`,
    );
  },
  // --------------- arrestee ---------------------\\
  getArrestees: async (
    caseId: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IArrestee[]>> => {
    return http.get(`api/${apiVersion}/cases/${caseId}/arrestees`, { params });
  },
  updateArrestee: async (
    caseId: string,
    masterNameId: string,
    data: IArresteeRequest,
  ): Promise<AxiosResponse<IArrestee>> => {
    return http.put(
      `api/${apiVersion}/cases/${caseId}/arrestees/${masterNameId}`,
      data,
    );
  },
  uploadArresteeFiles: async (
    caseId: string,
    files: File[],
  ): Promise<AxiosResponse<IFileItem[]>> => {
    const formData = generateFormData({ files });
    return http.post(
      `api/${apiVersion}/cases/${caseId}/arrestees/upload`,
      formData,
    );
  },
};
