import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IAgency } from '@/models';

import { http } from './http';
import { IListResponse } from './types';

export const AgencyApi = {
  getAgencies: async (): Promise<AxiosResponse<IListResponse<IAgency>>> => {
    return http.get(`api/${apiVersion}/agencies/`);
  },
  getAgency: async (id: string): Promise<AxiosResponse<IAgency>> => {
    return http.get(`api/${apiVersion}/agencies/${id}`);
  },
};
