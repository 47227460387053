import { FC } from 'react';

import { FormAddressField, FormAddressFieldProps } from './FormAddressField';
import {
  FormAutocompleteField,
  FormAutocompleteFieldProps,
} from './FormAutocompleteField';
import { FormCheckboxField, FormCheckboxFieldProps } from './FormCheckboxField';
import { FormDateField, DatePickersFormFieldProps } from './FormDateField';
import { FormDateTimeField } from './FormDateTimeField';
import { FormFilePicker, FormFilePickerProps } from './FormFilePicker';
import { FormInputField, FormInputFieldProps } from './FormInputField';
import { FormIntegerField } from './FormIntegerField';
import { FormPasswordField } from './FormPasswordField';
import { FormPatternField, FormPatternFieldProps } from './FormPatternField';
import { FormRadiosField, FormRadiosFieldProps } from './FormRadiosField';
import { FormRangeField, FormRangeFieldProps } from './FormRangeField';
import { FormSelectField, FormSelectFieldProps } from './FormSelectField';
import { FormSignatureField } from './FormSignatureField';
import { FormSSNField, FormSSNFieldProps } from './FormSSNField';
import { FormTabField, FormTabFieldProps } from './FormTabField';

export type IRenderFormFieldProps = FormInputFieldProps &
  FormSelectFieldProps &
  DatePickersFormFieldProps &
  FormPatternFieldProps &
  FormCheckboxFieldProps &
  FormAutocompleteFieldProps &
  FormAddressFieldProps &
  FormFilePickerProps &
  FormSSNFieldProps &
  FormTabFieldProps &
  FormRangeFieldProps &
  FormRadiosFieldProps;

export const RenderFormField: FC<IRenderFormFieldProps> = (props) => {
  const { type } = props;

  switch (type) {
    case 'select':
      return <FormSelectField {...props} />;
    case 'date':
      return <FormDateField {...props} />;
    case 'datetime':
      return <FormDateTimeField {...props} />;
    case 'pattern':
      return <FormPatternField {...props} />;
    case 'password':
      return <FormPasswordField {...props} />;
    case 'checkbox':
      return <FormCheckboxField {...props} />;
    case 'autocomplete':
      return <FormAutocompleteField {...props} />;
    case 'address':
      return <FormAddressField {...props} />;
    case 'file':
      return <FormFilePicker {...props} />;
    case 'tab':
      return <FormTabField {...props} />;
    case 'integer':
      return <FormIntegerField {...props} />;
    case 'ssn':
      return <FormSSNField {...props} />;
    case 'range':
      return <FormRangeField {...props} />;
    case 'signature':
      return <FormSignatureField {...props} />;
    case 'radios':
      return <FormRadiosField {...props} />;
    default:
      return <FormInputField {...props} />;
  }
};
