import { FC } from 'react';

import { AppModal } from '@/components';

interface ImageViewModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  name: string;
  url: string;
  width?: string;
}

export const ImageViewModal: FC<ImageViewModalProps> = ({
  isOpen = false,
  setOpen,
  name,
  url,
  width,
}) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppModal
        open={isOpen}
        title={name}
        onClose={onClose}
        classes={{ content: 'image-view-content' }}
      >
        <img src={url} alt="" style={{ width: width || '35vw' }} />
      </AppModal>
    </>
  );
};
