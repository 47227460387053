import { AgencyType, IAgency, IUser, Role } from '@/models';

export const isAccountAdmin = (user: IUser) => {
  return isTargetRole(user, Role.ACCOUNT_ADMIN);
};

export const isAgencyAdmin = (user: IUser, agencyId?: string) => {
  return isTargetAgencyRole(user, Role.AGENCY_ADMIN, agencyId);
};

export const isAdmin = (user: IUser, agencyId: string) => {
  return isAccountAdmin(user) || isAgencyAdmin(user, agencyId);
};

export const isTargetRole = (user: IUser, targetRole: Role) => {
  return !!user.roles?.find(({ role }) => role === targetRole);
};

export const isTargetAgencyRole = (
  user: IUser,
  targetRole: Role,
  agencyId?: string,
) => {
  return !!user.roles?.find(
    ({ role, agency }) =>
      role === targetRole && (!agencyId || agency === agencyId),
  );
};

export const getEmployeeAgencyId = (user: IUser) => {
  return user.roles.find(({ role }) => role === Role.EMPLOYEE)
    ?.agency as string;
};

// Check if user has another role except given roles
export const hasOtherRoleExcept = (user: IUser, roles: Role[]) => {
  return !!user.roles.find((userRole) => !roles.includes(userRole.role));
};

// Check if user has given role of given agency type
export const isAgencyTypeRole = (
  user: IUser,
  agencies: IAgency[],
  expectedRole: Role,
  expectedAgencyType: AgencyType,
) => {
  return !!user.roles.find((userRole) => {
    const agencyType = agencies.find(
      (agency) => agency._id === userRole.agency,
    )?.type;
    return agencyType === expectedAgencyType && expectedRole === userRole.role;
  });
};
