import { createContext, FC, ReactNode, useState } from 'react';

import { CaseApi } from '@/api/api.case';
import { Loader } from '@/components';
import { CaseRoleBasedAction } from '@/constants';
import { ICase } from '@/models/case';
import { isReadOnlyCaseRole } from '@/services';
import { useToastStore, useUserStore } from '@/store';

interface CaseContextValue {
  kase: ICase | null;
  fetchCase: (caseId: string) => Promise<ICase | null>;
  updateCase: (data: ICase | null) => void;
  isReadOnlyNames: boolean;
  isReadOnlyVehicles: boolean;
  editingSection?: string;
  setEditingSection: (sectionName: string) => void;
}

interface CaseProviderProps {
  children: ReactNode;
}

export const CaseContext = createContext<CaseContextValue>({
  kase: null,
  fetchCase: async () => null,
  updateCase: () => {},
  isReadOnlyNames: false,
  isReadOnlyVehicles: false,
  setEditingSection: () => {},
});

export const CaseProvider: FC<CaseProviderProps> = (props) => {
  const { children } = props;
  const { updateToast } = useToastStore();
  const { user } = useUserStore();
  const [kase, setCase] = useState<ICase | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editingSection, setEditingSection] = useState<string>();
  const isReadOnlyNames = isReadOnlyCaseRole(
    CaseRoleBasedAction.NAMES,
    user,
    kase,
  );
  const isReadOnlyVehicles = isReadOnlyCaseRole(
    CaseRoleBasedAction.VEHICLES,
    user,
    kase,
  );

  const fetchCase = async (CaseId: string) => {
    setIsLoading(true);
    try {
      const res = await CaseApi.getOne(CaseId);
      setCase(res.data);
      return res.data;
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const updateCase = (data: ICase | null) => {
    setCase(data);
  };

  return (
    <CaseContext.Provider
      value={{
        kase,
        fetchCase,
        updateCase,
        isReadOnlyNames,
        isReadOnlyVehicles,
        editingSection,
        setEditingSection,
      }}
    >
      {children}
      <Loader open={isLoading} />
    </CaseContext.Provider>
  );
};
