export const flattenObject = (
  obj?: Record<string, any>,
  parentKey?: string,
) => {
  let result: Record<string, any> = {};

  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      const _key = parentKey ? `${parentKey}.${key}` : key;
      if (typeof value === 'object' && !Array.isArray(value)) {
        result = { ...result, ...flattenObject(value, _key) };
      } else {
        result[_key] = value;
      }
    });
  }

  return result;
};

export const compositeObject = (key: string, value: any) => {
  let obj: any = value;
  const keyArr = key.split('.');
  for (let i = keyArr.length - 1; i > -1; i--) {
    if (!keyArr[i]) continue;
    obj = { [keyArr[i]]: obj };
  }
  return obj;
};

export const getItemValueFromObject: any = (
  obj: Record<string, any>,
  key?: string,
) => {
  if (!key) return obj;
  let itemValue = { ...obj };
  const keyArr = key.split('.');
  keyArr.forEach((_key) => {
    if (_key) {
      itemValue = itemValue ? itemValue[_key] : undefined;
    }
  });
  return itemValue;
};
