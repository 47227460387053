import { useMemo, useState } from 'react';

import { Stack, Typography, styled } from '@mui/material';

import {
  IcClose,
  IcFileCheck,
  IcFileX,
  IcImage,
  IcNote,
  IcVideoRecorder,
} from '@/assets/images';
import defaultImage from '@/assets/images/pngs/image.png';
import { IFile } from '@/models';
import { useImageStore } from '@/store';
import { colors } from '@/theme/variables';
import { fileSizeConverter } from '@/utils';

import { Input } from '..';
import { ImageViewModal } from '../Modals/ImageViewModal/ImageViewModal';

const FileListItemRoot = styled(Stack)(() => ({
  width: '100%',
  minHeight: 80,
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 10,
  border: `1px solid ${colors.grey['20']}`,
  borderRadius: 8,
  overflow: 'hidden',

  '& .icon_box': {
    display: 'flex',
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${colors.grey['20']}`,
    background: colors.grey[10],
  },

  '& .image_preview': {
    width: 80,
    height: 80,
    objectFit: 'cover',
    cursor: 'pointer',
  },

  '& .content_box': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    overflow: 'hidden',

    '& .txt_name': {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: colors.black,
    },

    '& .txt_size': {
      fontSize: '0.75rem',
      color: colors.grey[80],
    },

    '& .txt_error': {
      fontSize: '0.75rem',
      color: colors.error.main,
    },

    '& .icon_remove': {
      color: colors.error.main,
      cursor: 'pointer',
    },
  },
}));

const CLASS_CASE_FILE_ITEM = 'case-file-list-item';

export interface IFileItem {
  name: string;
  url: string;
  size: number;
  mimeType?: string;
  type?: string;
  error?: string;
}

interface FileListItemProps {
  className?: string;
  item: IFileItem;
  onRemove: (url: string) => void;
}

export const FileListItem = (props: FileListItemProps) => {
  const { className, onRemove, item } = props;
  const { name, url, size, error, mimeType } = item;
  const { sasToken, fetchImageToken, checkTokenExpiration } = useImageStore();
  const [openImageViewModal, setOpenImageViewModal] = useState<boolean>(false);

  const isEditDescription = className === CLASS_CASE_FILE_ITEM;

  const tokenImage = useMemo(() => {
    const isExpired = checkTokenExpiration();
    if (isExpired) {
      fetchImageToken();
      return '';
    } else {
      return `${url}?${sasToken}`;
    }
  }, [url, sasToken]);

  const isImageFile = useMemo(() => {
    return (mimeType || '').includes('image');
  }, [mimeType]);

  const isVideoFile = useMemo(() => {
    const nameCaseInsensitive = name.toLowerCase();
    return (
      (mimeType || '').includes('video') ||
      nameCaseInsensitive.includes('.avi') ||
      nameCaseInsensitive.includes('.mov') ||
      nameCaseInsensitive.includes('.wmv')
    );
  }, [mimeType]);

  const renderFileIcon = () => {
    return isEditDescription ? (
      <div className="icon_box">
        {isVideoFile ? (
          <IcVideoRecorder />
        ) : isImageFile ? (
          <IcImage />
        ) : (
          <IcNote /> // Return Note since accepted files for Case file are images, videos and few docs types.
        )}
      </div>
    ) : error ? (
      <div className="icon_box">
        <IcFileX />
      </div>
    ) : isImageFile ? (
      <img
        onClick={handleModalOpen}
        className="image_preview"
        src={tokenImage || defaultImage}
        alt={name}
        loading="lazy"
      />
    ) : (
      <div className="icon_box">
        <IcFileCheck />
      </div>
    );
  };

  const handleDescriptionChange = (fileItem: IFile, description: string) => {
    fileItem.description = description;
  };

  const handleModalOpen = () => {
    setOpenImageViewModal(true);
  };

  return (
    <FileListItemRoot className={className}>
      {renderFileIcon()}
      <div className="content_box">
        <div>
          <Typography className="txt_name">{name}</Typography>
          {!error ? (
            <Typography className="txt_size">
              {fileSizeConverter(size)}
            </Typography>
          ) : (
            <Typography className="txt_error">{error}</Typography>
          )}
        </div>
        {isEditDescription && (
          <Input
            style={{ marginTop: '8px' }}
            name="description"
            placeholder="Description"
            onChange={(v) =>
              handleDescriptionChange(item as IFile, v.target.value)
            }
          />
        )}
        <IcClose
          className="icon_remove"
          onClick={() => onRemove(url)}
          style={{ marginLeft: isEditDescription ? '15px' : '' }}
        />
      </div>
      <ImageViewModal
        isOpen={openImageViewModal}
        setOpen={setOpenImageViewModal}
        name={name}
        url={tokenImage || defaultImage}
      />
    </FileListItemRoot>
  );
};
