import { TableColumn } from '@/components';

export const masterIndexNameListTableColumns: TableColumn[] = [
  { key: 'fullName', label: 'Full name' },
  { key: 'birthday', label: 'Date of birth' },
  { key: 'phone', label: 'Phone number' },
  { key: 'race', label: 'Race' },
  { key: 'ssn', label: 'Social security number' },
  { key: 'gender', label: 'Gender' },
  { key: 'actions', label: '' },
];
