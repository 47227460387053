import { Button } from '@mui/material';

import { MasterNameAutocomplete, NoResultItem } from '@/components';
import { ICFSMasterName, IMasterNamePerson, MasterNameType } from '@/models';
import { getAddressLocation } from '@/services';

import { ReporterItem } from './ReporterItem';

export interface PrimaryReporterFormProps {
  masterName: IMasterNamePerson | undefined;
  excludedMasterNames?: ICFSMasterName[];
  onChange: (fieldName: string, newValue: any) => Promise<void>;
  onKeyUp?: (e: any) => Promise<void>;
  onRemove: any;
  handleModalOpen?: () => void;
}

export const PrimaryReporterForm = ({
  masterName,
  excludedMasterNames,
  onChange,
  onKeyUp,
  onRemove,
  handleModalOpen,
}: PrimaryReporterFormProps) => {
  const address = masterName?.address;

  return !masterName ? (
    <MasterNameAutocomplete
      excludedMasterNames={excludedMasterNames?.map(
        (masterNameItem: ICFSMasterName) => {
          return masterNameItem.masterName as IMasterNamePerson;
        },
      )}
      onChange={(v) => {
        onChange('masterName', v);
      }}
      onKeyUp={onKeyUp}
      filterByTypes={[MasterNameType.PERSON]}
      noResults={
        <NoResultItem flexDirection="row" alignItems="center" sx={{ ml: -1 }}>
          {!!handleModalOpen && (
            <Button onClick={handleModalOpen} variant="text" color="error">
              + Add new
            </Button>
          )}
        </NoResultItem>
      }
    />
  ) : (
    <ReporterItem
      name={`${masterName.firstName} ${masterName.lastName || ''}`}
      address={getAddressLocation(address)}
      onEdit={handleModalOpen}
      onRemove={onRemove}
    />
  );
};
