export const IncomingCallAddressFormFields = [
  {
    title: 'Address information',
    items: [
      {
        name: 'address',
        label: 'Address',
        type: 'address',
        styles: {
          xs: 12,
        },
        associatedField: {
          title: 'Associated addresses',
        },
      },
    ],
  },
];

export const IncomingCallReporterFormFields = [
  {
    title: 'Reporter information',
    items: [
      {
        name: 'reporter',
        type: 'text',
        label: 'Reporter name',
        grid: {
          md: 12,
        },
        associatedField: {
          title: 'Associated names',
        },
      },
    ],
  },
];

export const IncomingCallDefaultValues = {
  address: '',
  reporter: '',
};
