import { IFileItem } from '@/components/FilePicker/FileListItem';
import {
  IAddress,
  ICaseInvolvement,
  ICFS,
  IIncidentCode,
  IMasterName,
  IMasterVehicle,
  ISupervisor,
  IUser,
} from '@/models';

import { IAuditModel, ITimestampsModel, ObjectId } from './base';
import { IFile } from './file';

export enum CaseStatus {
  OPEN = 'OPEN',
  WAITING_FOR_SUPERVISOR = 'WAITING_FOR_SUPERVISOR',
  APPROVED_BY_SUPERVISOR = 'APPROVED_BY_SUPERVISOR',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum AttemptIndicator {
  ATTEMPTED = 'A',
  COMPLETED = 'C',
}

export enum LarcenyType {
  POCKET_PICKING = '01',
  PURSE_SNATCHING = '02',
  SHOPLIFTING = '03',
  THEFT_FROM_BUILDING = '04',
  THEFT_FROM_COIN_OPERATED_MACHINE = '05',
  THEFT_FROM_MOTOR_VEHICLE = '06',
  THEFT_OF_MOTOR_VEHICLE_PARTS = '07',
  THEFT_FROM_MAILBOX = '08',
  ALL_OTHER_LARCENY_OFFENSES = '09',
  MOTOR_VEHICLE_THEFT = '10',

  // The offense is NOT 23A, 23B, 23C, 23D, 23E, 23F, 23G, or 23H
  NOT_APPLICABLE = '77',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum LocationType {
  // Residential Structures
  SINGLE_FAMILY_HOME = '01',
  MULTIPLE_DWELLING = '02',
  RESIDENTIAL_FACILITY = '03',
  OTHER_RESIDENTIAL = '04',
  GARAGE_SHED = '05',
  // Mission/Homeless
  SHELTER = '54',

  // Public Access Building
  TRANSIT_FACILITY = '06',
  GOVERNMENT_OFFICE = '07',
  SCHOOL = '08',
  COLLEGE = '09',
  CHURCH = '10',
  HOSPITAL = '11',
  JAIL_PRISON = '12',
  PARKING_GARAGES = '13',
  OTHER_PUBLIC_ACCESS_BUILDING = '14',
  COMMUNITY_CENTER = '57',
  DAYCARE_FACILITY = '63',

  // Commercial Service Locations
  AUTO_SHOP = '15',
  FINANCIAL_INSTITUTION = '16',
  BARBER_BEAUTY_SHOP = '17',
  HOTEL_MOTEL = '18',
  DRY_CLEANERS_LAUNDRY = '19',
  PROFESSIONAL_OFFICE = '20',
  DOCTOR_OFFICE = '21',
  OTHER_BUSINESS_OFFICE = '22',
  AMUSEMENT_CENTER = '23',
  RENTAL_STORAGE_FACILITY = '24',
  OTHER_COMMERCIAL_SERVICE_LOCATION = '25',
  ATM_SEPARATE_FROM_BANK = '61',

  // Commercial Retail Locations
  BAR = '26',
  BUY_SELL_TRADE_SHOP = '27',
  RESTAURANT = '28',
  GAS_STATION = '29',
  AUTO_SALES_LOT = '30',
  JEWELRY_STORE = '31',
  CLOTHING_STORE = '32',
  DRUG_STORE = '33',
  LIQUOR_STORE = '34',
  SHOPPING_MALL = '35',
  SPORTING_GOODS = '36',
  GROCERY_SUPERMARKET = '37',
  VARIETY_CONVENIENCE_STORE = '38',
  DEPARTMENT_DISCOUNT_STORE = '39',
  OTHER_RETAIL_STORE = '40',
  FACTORY_MILL_PLANT = '41',
  OTHER_BUILDING = '42',

  // Outside
  YARD = '43',
  CONSTRUCTION_SITE = '44',
  LAKE_WATERWAY = '45',
  FIELD_WOODS = '46',
  STREET = '47',
  PARKING_LOT = '48',
  PARK_PLAYGROUND = '49',
  CEMETERY = '50',
  PUBLIC_TRANSIT_VEHICLE = '51',
  OTHER_OUTSIDE_LOCATION = '52',
  REST_AREA = '53',
  CAMP_CAMPGROUND = '62',

  // Other
  TRIBAL_LANDS = '56',
  CYBERSPACE = '58',
  ABANDONED_CONDEMNED_STRUCTURE = '59',
  AREA_STADIUM_FAIRGROUNDS_COLISEUM = '60',
  DOCK_WHARF_FREIGHT_MODAL_TERMINAL = '64',
  FARM_FACILITY = '65',
  GAMBLING_FACILITY_CASINO_RACETRACK = '66',
  MILITARY_INSTALLATION = '67',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum WeaponForce {
  FIREARM = '01',
  REVOLVER_DERRINGER_SINGLE_SHOT_PISTOL = '02',
  SEMIAUTOMATIC_HANDGUN = '03',
  FULLY_AUTOMATIC_HANDGUN_SUBMACHINE_GUN = '04',
  SINGLE_SHOT_PUMP_ACTION_BOLT_ACTION_RIFLE = '05',
  SEMIAUTOMATIC_RIFLE = '06',
  FULLY_AUTOMATIC_RIFLE_MACHINE_GUN = '07',
  SHOTGUN = '08',
  LMITATION_FIREARM = '09',
  SIMULATED_FIREARM = '10',
  KNIFE_CUTTING_INSTRUMENT = '11',
  BLUNT_OBJECT = '12',
  MOTOR_VEHICLE_VESSEL = '13',
  PERSONAL_WEAPONS = '14',
  POISONS = '15',
  EXPLOSIVES = '16',
  FIRE_INCENDIARY_DEVICE = '17',
  DRUG_NARCOTICS_SLEEPING_PILLS = '18',
  OTHER_WEAPON = '19',
  ASPHYXIATION = '85',
  NOT_APPLICABLE = '77',
  UNKNOWN = '99',
}

export enum MethodEntry {
  FORCE = 'F',
  NO_FORCE = 'N',
  NOT_APPLICABLE = 'X',
}

export enum AssaultHomicideCircumstance {
  // For IBR code 09A, 13A
  ARGUMENT = '01',
  ASSAULT_ON_LAW_ENFORCEMENT_OFFICER = '02',
  DISPUTE_AMONG_DRUG_DEALERS = '03',
  DRUG_BUYER_DEALER_DISPUTE = '04',
  ROBBERY_THEFT_FROM_DRUG_DEALER = '05',
  DISPUTE_BETWEEN_DRUG_DEALER_AND_WITNESS_COMPLAINANT = '06',
  OTHER_DRUG_RELATED = '07',
  GANGLAND = '08',
  JUVENILE_GANG = '09',
  DOMESTIC_VIOLENCE = '10',
  MERCY_KILLING = '11',
  OTHER_FELONY_INVOLVED = '12',
  OTHER_CIRCUMSTANCES = '20',
  UNKNOWN_CIRCUMSTANCES = '21',

  // For IBR code 09B
  CHILD_PLAYING_WITH_WEAPON = '30',
  GUN_CLEANING_ACCIDENT = '31',
  HUNTING_ACCIDENT = '32',
  OTHER_NEGLIGENT_WEAPON_HANDLING = '33',
  NEGLIGENT_VEHICULAR_KILLINGS = '34',
  OTHER_NEGLIGENT_KILLINGS = '40',

  NOT_APPLICABLE = '77',
}

export enum JustifiableHomicideCircumstance {
  // Criminal killed by police officer
  CRIMINAL_ATTACKED_POLICE_OFFICER_AND_THAT_OFFICER_KILLED_CRIMINAL = '01',
  CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_ANOTHER_POLICE_OFFICER = '02',
  CRIMINAL_ATTACKED_CIVILIAN = '03',
  CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME = '04',
  CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME = '05',
  CRIMINAL_RESISTED_ARREST = '06',
  UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION = '09',

  // Criminal killed by private citizen
  CRIMINAL_ATTACKED_CIVILIAN_AND_KILLED_BY_PC = '13',
  CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME_AND_KILLED_BY_PC = '14',
  CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME_AND_KILLED_BY_PC = '15',
  CRIMINAL_RESISTED_ARREST_AND_KILLED_BY_PC = '16',
  UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION_KILLED_BY_PC = '19',
  CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_CIVILIAN_AIDING_POLICE = '17',

  NOT_APPLICABLE = '77',
}

export enum OffenderUsedComputerOrHandheldDevice {
  COMPUTER_HANDHELD_DEVICE_NOT_USED = '0',
  COMPUTER_HANDHELD_DEVICE_USED = '1',
  NOT_REPORTED = '8',
}

export enum CriminalActivityGangInformationType {
  // Criminal Activity Type
  SIMPLE_GROSS_NEGLECT = 'A', // only 720-Animal Cruelty
  BUYING_RECEIVING = 'B',
  CULTIVATING_MANUFACTURING_PUBLISHING = 'C', // production of any type
  DISTRIBUTION_SELLING = 'D',
  EXPLOITING_CHILDREN = 'E',
  ORGANIZED_ABUSE = 'F', // only 720-Animal Cruelty
  INTENTIONAL_ABUSE_TORTURE = 'I', // only 720-Animal Cruelty
  OPERATING_PROMOTING_ASSISTING = 'O',
  POSSESSING_CONCEALING = 'P',
  ANIMAL_SEXUAL_ABUSE = 'S', // only 720-Animal cruelty
  TRANSPORTING_TRANSMITTING_IMPORTING = 'T',
  USING_CONSUMING = 'U',
  NOT_APPLICABLE = 'X',

  // Gang Information Type
  JUVENILE_GANG = 'J', // membership is predominantly under 18 years of age
  OTHER_GANG = 'G', // membership is predominantly 18 years of age or older
  UNKNOWN = 'N',
}

export enum BiasMotivation {
  // Race
  ANTI_WHITE = '11',
  ANTI_BLACK_AFRICAN_AMERICAN = '12',
  ANTI_AMERICAN_INDIAN_ALASKA_NATIVE = '13',
  ANTI_ASIAN = '14',
  ANTI_MULTIPLE_RACES_GROUP = '15',
  ANTI_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER = '16',

  // Religion
  ANTI_RELIGIOUS_PRACTICE = '20',
  ANTI_JEWISH = '21',
  ANTI_ROMAN_CATHOLIC = '22',
  ANTI_PROTESTANT = '23',
  ANTI_ISLAMIC = '24',
  ANTI_MULTIPLE_RELIGIONS_GROUP = '26',
  ANTI_ATHEISM_AGNOSTICISM = '27',
  ANTI_MORMON = '28',
  ANTI_OTHER_RELIGION = '29',
  ANTI_JEHOVAH_WITNESS = '30',
  ANTI_EASTERN_ORTHODOX = '81',
  ANTI_OTHER_CHRISTIAN = '82',
  ANTI_BUDDHIST = '83',
  ANTI_HINDU = '84',
  ANTI_SIKH = '85',

  // Ethnicity,national origin, ancestry
  ANTI_HISPANIC_LATINO = '31',
  ANTI_ARAB = '32',
  ANTI_OTHER_RACE_ETHNICITY_ANCESTRY = '34',
  ANTI_NOT_HISPANIC_LATINO = '35',
  ANTI_OTHER_ORIGIN = '36',

  // Sexual Orientation
  ANTI_GAY = '41',
  ANTI_LESBIAN = '42',
  ANTI_LESBIAN_GAY_BISEXUAL_TRANSGENDER = '43',
  ANTI_HETEROSEXUAL = '44',
  ANTI_BISEXUAL = '45',

  // Other
  ANTI_OLD_AGE = '04',
  ANTI_PHYSICAL_DISABILITY = '51',
  ANTI_MENTAL_DISABILITY = '52',
  ANTI_MALE = '61',
  ANTI_FEMALE = '62',
  ANTI_TRANSGENDER = '71',
  ANTI_GENDER_NON_CONFORMING = '72',
  NOT_APPLICABLE = '77',
  NOT_REPORTED_BY_AGENCY = '88',
  UNKNOWN = '99',
}

export enum VictimRelationship {
  // Within family
  SPOUSE = '01',
  FORMER_INTIMATE_PARTNER = '02',
  PARENT = '03',
  SIBLING = '04',
  CHILD = '05',
  GRANDPARENT = '06',
  GRANDCHILD = '07',
  IN_LAW = '08',
  STEPPARENT = '09',
  STEPCHILD = '10',
  STEP_SIBLING = '11',
  OTHER_FAMILY = '12',
  CURRENT_BOYFRIEND_GIRLFRIEND = '22',
  CHILD_OF_INTIMATE_PARTNER = '23',
  CURRENT_SAME_SEX_RELATIONSHIP = '27',
  EX_SPOUSE = '29',

  // Outside family but known to victim
  ACQUAINTANCE = '20',
  FRIEND = '26',
  NEIGHBOR = '28',
  BABY_CHILD = '21',
  EMPLOYEE = '24',
  EMPLOYER = '25',
  OTHERWISE_KNOWN = '30',

  // Not known by victim
  RELATIONSHIP_UNKNOWN = '40',
  STRANGER = '41',

  // Other
  VICTIM_WAS_OFFENDER = '70',
  NOT_APPLICABLE = '77',
  NOT_REPORTED = '80',
}

export const CaseTypes = {
  INCIDENT_REPORT: 'Incident Report',
  NARRATIVE_ONLY_REPORT: 'Narrative Only',
  FIELD_INTERVIEW_REPORT: 'Field Interview',
} as const;
export type ICaseType = keyof typeof CaseTypes;

export const DispositionReasons = {
  '01': 'Arrest-Adult',
  '02': 'Arrest-Juvenile ',
  '10': 'Death of Offender',
  '11': 'Prosecution Declined ',
  '12': 'Extradition Denied',
  '13': 'Victim Refuses to Cooperate',
  '14': 'No Court Referral (Juvenile Only)',
  '20': 'Warrant Issued',
  '21': 'Investigation Pending',
  '22': 'Closed',
  '88': 'Not Reported',
  '99': 'Unknown',
} as const;
export type IDispositionReason = keyof typeof DispositionReasons;
export interface IDisposition {
  reason: IDispositionReason;
  createdBy: IUser;
  comment: string;
  createdAt: Date;
}
export interface IIncidentInfoForm {
  biasMotivations: BiasMotivation[];
  involveCargoTheft: boolean;
}

interface IAssignmentInfo {
  // CASE_APPROVER who will be responsible for case approval
  // As default, case will be assigned to supervisor of case submitter
  // It can be reassigned to other CASE_APPROVER
  assignedTo?: string | null;
  comment?: string | null;
  assignedAt?: Date | null;
}
export interface IPriorityInfo {
  isPriority: boolean;
  comment?: string;
  updatedBy?: ISupervisor;
  updatedAt?: Date;
}

export interface ICase extends IAuditModel, IIncidentInfoForm {
  number: string;
  status: CaseStatus;
  createdBy: IUser;
  address?: IAddress;
  incidentStartedAt?: Date;
  incidentReportedAt?: Date;
  isSentToState: boolean;
  sentToStateAt?: Date;
  isSentToNIBRS: boolean;
  sentToNIBRSAt?: Date;
  files?: IFile[];
  primaryOfficer: IUser;
  agency: string;
  incidentCodes?: string[];
  dispositions?: IDisposition[];
  rejectedInfo?: ICaseRejection[];
  primaryInvestigator?: IUser;
  type: ICaseType;
  approvedInfo: Array<IApprovedInfo>;
  priorityInfo: IPriorityInfo;
  priorityComment: string;
  assignmentInfo?: IAssignmentInfo;
  locationCode?: ICTVCode;
  sdpIdentifier?: string;
  cfsShortDescription?: string;
}

export interface ICaseFile extends IAuditModel {
  fileName: string;
  description: string;
  dateUploaded: Date;
  uploadedBy: IUser;
  fileType: IFileItem;
}

export interface ICaseVehicle extends IAuditModel {
  plateNumber: string;
  plateState: string;
  color: string;
  make: string;
  model: string;
  vin: string;
  year: string;
  impounded: boolean;
}

export interface ICaseNewForm {
  _id?: string;
  incidentStartedAt: Date;
  incidentReportedAt?: Date;
  primaryOfficer: string;
  primaryInvestigator: string;
  incidentCodes: string[];
  address: IAddress;
  type: ICaseType;
  locationCode?: string;
  sdpIdentifier?: string;
}

export interface ICaseCreateRequest {
  _id?: string;
  address: string;
  incidentStartedAt: string;
  primaryOfficer: string;
  primaryInvestigator: string;
  incidentCodes: string[];
  type: ICaseType;
  locationCode?: string;
  sdpIdentifier?: string;
}

export interface ICaseMasterName extends IAuditModel {
  caseId: string;
  masterName: IMasterName;
  relationship: ICaseInvolvement;
  additionalInfo?: any; // TODO: this needs to be properly typed
}

export interface ICaseCfsLink extends ITimestampsModel, ObjectId {
  caseId: string;
  cfs: ICFS;
}

export interface ICaseCaseLink extends ITimestampsModel, ObjectId {
  linked: ObjectId & {
    number: string;
    address: IAddress;
    incidentReportedAt: Date;
    incidentCodes: IIncidentCode[];
  };
  main: ObjectId & { number: string };
}
export interface ICaseRejectRequest {
  reason: string;
}

export interface ICaseMasterVehicle extends IAuditModel {
  caseId: string;
  masterVehicle: IMasterVehicle;
}

export enum CaseMasterNameInvolvement {
  OFFENDER = 'OFFENDER',
  WITNESS = 'WITNESS',
  VICTIM = 'VICTIM',
}

export enum CaseMasterOrganizaionInvolvement {
  VICTIM = 'VICTIM',
}

export interface ICaseRejection {
  reason: string;
  rejectedBy: IUser;
  rejectedAt: Date;
}

export interface IApprover extends ObjectId {
  firstName: string;
  lastName: string;
}

export interface IApprovedInfo {
  approvedBy?: IApprover;
  approvedAt?: Date;
}

export interface IPenalCode extends IAuditModel {
  lawTitle: string;
  section: string;
  subsection: string;
  statutoryClass: string;
  category: string;
  degree: string;
  ibrCode: string;
  maxiLawDescription: string;
  miniLawDescription: string;
  lawDescription: string;
}

export interface IOffenseForm extends ObjectId {
  attemptIndicator?: AttemptIndicator;
  // Applicable for IBR code 23A, 23B, 23C, 23D, 23E, 23F, 23G, 23H
  larcenyType?: LarcenyType;
  locationType?: LocationType;
  // maximum 3 entries
  weaponForces: WeaponForce[];
  // Applicable for IBR code 220(Burglary). Valid range 01 - 99, 00 is NotApplicable
  numberOfPremisesEntered?: string;
  // Applicable for IBR code 220(Burglary)
  methodOfEntry?: MethodEntry;
  // Applicable for IBR code 09A, 09B, 13A(Murder/Negligent, Non-Negligent Manslaughter/Aggravated Assault)
  // 2 entries
  assaultHomicideCircumstances: AssaultHomicideCircumstance[];
  // Applicable for IBR code 09C - Justifiable Homicide
  justifiableHomicideCircumstance?: JustifiableHomicideCircumstance;
  offenderUsedComputerOrHandheldDevice?: OffenderUsedComputerOrHandheldDevice;
  // 3 entries
  criminalActivityGangInformationTypes: CriminalActivityGangInformationType[];
}

export interface IOffense extends IAuditModel, IOffenseForm {
  number: number;
  penalCode: IPenalCode;
  offenders: [];
  victims: [];
}

export interface IOffenseRequest extends IOffenseForm {
  penalCode: string;
}

export interface IPriorityForm {
  comment: string;
}

export interface IOffenderForm {
  offenderId: string;
  victims: IOffenderVictimForm[];
}

export interface IOffenderVictimForm {
  victim: string;
  relationship: VictimRelationship;
}

export interface IOffenderVictim extends Omit<IOffenderVictimForm, 'victim'> {
  victim: IMasterName;
}

export interface IOffenseOffender extends IAuditModel {
  offense: string;
  offender: IMasterName;
  victims: IOffenderVictim[];
}

export enum ArrestType {
  COMPLAINT = '01',
  CRIME_IN_PROGRESS = '02',
  COURT_SUMMONS = '04',
  ORDER_OF_PROTECTION = '05',
  OTHER = '06',
}

export enum ArrestStatus {
  HELD = '01',
  APPEARANCE_TICKET = '02',
  ROR = '03',
  CASH_BAIL = '04',
  BAIL_BOND = '05',
  POLICE_BAIL = '06',
  RELEASED_TO_THIRD_PARTY = '07',
  NOT_REPORTED = '88',
  UNKNOWN = '99',
}

export enum JuvenileReleaseStatus {
  HANDLED_WITHIN_DEPARTMENT = 'H', // e.g. released to parents, released with warning, etc
  REFERRED_TO_CRIMINAL_FAMILY_COURT_PROBATION_INTAKE = 'R',
  DIVERTED_TO_COUNSELING_TREATMENT_PROGRAM = 'D',
  NOT_APPLICABLE = 'X', // arrestee is 18 years old or older
}

export interface IArrestChargeForm {
  penalCode?: string;
  attemptIndicator: AttemptIndicator;
}

export interface IArrestFileForm {
  files: IFile[];
}

export interface IFileDescriptionForm {
  description: string;
}

export interface IArrestCharge extends Omit<IArrestChargeForm, 'penalCode'> {
  penalCode: IPenalCode;
}

export interface IArresteeForm {
  arrestLocation?: IAddress;
  arrestDate?: Date | null;
  arrestType?: ArrestType | null;
  arrestStatus?: ArrestStatus | null;
  arrestingOfficer?: string | null; // police user
  bookingOfficer?: string | null;
  condition?: string | null;
  attitude?: string | null;
  medicalAttention?: string | null;
  injury?: string | null;
  drugsNarcotics?: string | null;
  bloodAlcoholLevel?: string | null;
  juvenileReleaseStatus?: JuvenileReleaseStatus | null;
  arresteeWeapons?: WeaponForce[] | null;
  arrestLarcenyType?: LarcenyType | null;
  notes?: string | null;
  files?: IFile[] | null;
  arrestCharges?: IArrestChargeForm[] | null;
}

export interface IArrestee
  extends IAuditModel,
    Omit<
      IArresteeForm,
      'arrestingOfficer' | 'bookingOfficer' | 'arrestCharges'
    > {
  caseId: string;
  arrestee: IMasterName;
  number: string;
  arrestingOfficer?: IUser;
  bookingOfficer?: IUser;
  arrestCharges?: IArrestCharge[] | null;
}

export interface IArresteeRequest
  extends Omit<IArresteeForm, 'arrestLocation'> {
  arrestLocation?: string;
}

export enum GeographicType {
  NYS_COUNTY = 'NYS County',
  CITY = 'City',
  VILLAGE = 'Village',
  TOWN = 'Town',
  NYPD_PRECINCT = 'NYPD Precinct',
  INDIAN_TERRITORY = 'Indian Territory',
  FOREIGN_COUNTRY = 'Foreign Country',
  STATE = 'State',
}

export interface ICTVCode extends IAuditModel {
  code: string;
  geographicType: GeographicType;
  locationLiteral: string;
}
