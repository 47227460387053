import { create } from 'zustand';

import { ImageToken, StorageApi } from '@/api';

const initialState: ImageToken = {};

interface ImageStoreState extends ImageToken {
  fetchImageToken: () => void;
  checkTokenExpiration: () => boolean;
  fetching: boolean;
  error?: string;
}

export const useImageStore = create<ImageStoreState>((set, get) => ({
  ...initialState,
  fetching: false,
  checkTokenExpiration: () => {
    const { expiresOn } = get();
    return (
      !expiresOn ||
      new Date(expiresOn).getTime() - (new Date().getTime() + 60000) < 0
    );
  },
  fetchImageToken: async () => {
    const { fetching } = get();

    if (!fetching) {
      try {
        set({ fetching: true });
        const res = await StorageApi.getSasToken();
        set({ fetching: false, ...res.data });
      } catch (err: any) {
        set({ fetching: false, error: err.msg });
      }
    }
  },
}));
