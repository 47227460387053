import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { ICall } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const IncomingCallsApi = {
  getCalls: async (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICall>>> => {
    return http.get(`api/${apiVersion}/incoming-calls`, {
      params,
    });
  },
  updateCallReadStatus: async (
    _id: string,
  ): Promise<AxiosResponse<IListResponse<ICall>>> => {
    return http.patch(`api/${apiVersion}/incoming-calls/${_id}/read`);
  },
};
